import React, { useEffect, useState } from 'react';
import Datatable from '../Airdropdatatable/Datatable';
import NewNav from '../Navbar/NewNav';
import Footer from '../Footer/Footer';
import Card from './Card';
import UserdashboardCard from './Userdashboardcard';
import { useGetAirdropsQuery, useSignInMutation } from '../../config/Apibase';
import { CustomFormikProvider } from '../../hooks/contextFormikHook';
import Navbar from '../Navbar/Navbar';
import { useSelector } from 'react-redux';
import { selectUserConnectedWallet } from '../../redux/slices/userSlice';

const Usersdashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [previousResponse, setPreviousResponse] = useState(null);
  const walletForClaim = useSelector(selectUserConnectedWallet);
  const { data, refetch, isLoading } = useGetAirdropsQuery({
    page: currentPage,
    walletForClaim,
    isClaim: null,
    shouldActive: true,
  });
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    if (data) {
      setPreviousResponse([...data.airdrops]);
      setTotalPages(data.totalPages);
    }
  }, [data]);

  return (
    <div>
      <Navbar />
      <>
        <CustomFormikProvider>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {previousResponse?.map((item, index) => (
                  <UserdashboardCard item={item} key={index} index={index} />
                ))}
              </div>
            </div>
          </div>
        </CustomFormikProvider>
        <div className="user_dashboard_page_space"></div>
      </>
      <Footer />
    </div>
  );
};

export default Usersdashboard;
