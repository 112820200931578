import React, { useState } from 'react';
import { useGetClaimCountQuery } from '../../config/Apibase';
import './ClaimedAirdrops.css';

const ClaimedAirdrops = ({ targetDate, airdropId }) => {
  // const [status, setStatus] = useState();
  // const [apiData, setApiData] = useState();
  // const response = useGetClaimAllQuery();
  // const claims = response?.data?.claims || [];
  // console.log('claimedAd >>>>>>>>>>>>', claims);

  // const key = 'status';
  // const value = "approved";

  // console.log(airdropId)

  // const filteredArray = claims.filter(obj => obj[key] === value);
  // console.log("filtered array>>>>>>>>>>>>>>", filteredArray);

  // const filterAirdrop = filteredArray.filter(obj => obj.airdrop === airdropId);
  // console.log("Airdrop>>>>>>>>>>>>>>", filterAirdrop);

  // const status = 'approved';

  const endTime = new Date(targetDate);
  const currentTime = new Date();

  const isLive = currentTime < endTime;

  const response = useGetClaimCountQuery({ id: airdropId, status: isLive });
  const receivedRequest = response?.currentData?.claimCount;
  const walletAddress = response?.data?.claimWalletAddress || [];  

  if (!isLive) {
    return (
      <div className="card claimed_airdrop_list_card text-center">
        {walletAddress.length === 0 ? (
          <p className="no_claim_text mt-auto mb-auto">No claimed airdrops</p>
        ) : (
          <>
            <p className="address_list_text">Address List ({walletAddress.length})</p>
              {walletAddress.map((item, index) => (
                // <li className="address_text" key={index}>
                //   {item?.walletAddress}
                // </li>
                <div key={index}>
                  {/* <div className=""> */}
                    <p className='address_text mt-1 mb-1'>{item?.walletAddress}</p>
                    {/* <div className="ms-auto">Payment</div> */}
                  {/* </div> */}
                  <hr />
                </div>
              ))}
          </>
        )}
      </div>
    );
  }

  return (
    <div className="card claimed_airdrop_card">
      <div className="claimed_airdrop_card text-center d-flex flex-column justify-content-center">
        <p className="claimed_airdrop_number pb-0 mb-0">{receivedRequest}</p>
        <p className="claimed_airdrop_text pt-0 mt-0">Received AirDrops</p>
        {/* {airdropId} */}
      </div>
    </div>
  );
};

export default ClaimedAirdrops;
