import React, { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useToast } from '@chakra-ui/react';
import Web3Modal from 'web3modal';
// import toast from 'react-hot-toast';

import abi from './abi.json';
import { ChainId, airDropAddress } from './constant';
import { ToastContainer, toast } from 'react-toastify';

export const AirDropContext = React.createContext();
console.log(process.env.REACT_AIRDROP_ADDRESS, 'airDropAddressairDropAddress');
const AirDrop = (signerOrProvider) => new ethers.Contract(airDropAddress, abi, signerOrProvider);

export const AirDropProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState('');
  // const toast = useToast();
  const claimTxn = async (recipients, amounts, timestamp, signature, airdropID) => {
    console.log('🚀 ~ claimTxn ~ airdropID:', airdropID);
    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const accounts = await window.ethereum?.request({
        method: 'eth_requestAccounts',
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const contract = AirDrop(signer);

      // console.log(contract, 'contract');
      // console.log(
      //   recipients,
      //   amounts,
      //   timestamp,
      //   signature,
      //   'recipients, amounts, timestamp, signaturerecipients, amounts, timestamp, signature',
      // );
      const transaction = await contract
        .connect(signer)
        .airdrop(recipients, amounts, timestamp, [signature], ethers.utils.formatBytes32String(airdropID));
      // if (transaction) {

      //   // toast({
      //   //   position: 'top-center',
      //   //   title: 'Transaction',
      //   //   description: 'Transaction Successfuly Complete',
      //   //   status: 'success',
      //   //   duration: 9000,
      //   //   isClosable: true,
      //   // });
      // }
      const receipt = await transaction.wait();

      if (receipt.status === 1) {
        // status 1 means the transaction was successful
        toast.success('Transaction successful.', {
          position: 'top-left',
        });
        // Uncomment and use if you prefer toast with more options
        // toast({
        //   position: 'top-center',
        //   title: 'Transaction',
        //   description: 'Transaction Successfully Complete',
        //   status: 'success',
        //   duration: 9000,
        //   isClosable: true,
        // });
        setTimeout(() => {
          location.reload();
        }, 2000);
      } else {
        toast.error('Transaction failed.', {
          position: 'top-left',
        });
      }

      // await transaction.wait();
      // const handleNewNotification = () => {
      // if (!notificationDisplayed) {
      //   toast({
      //     position: 'top-left',
      //     title: 'Not Authenticated',
      //     description: 'Please connect to a Metamask Wallet',
      //     status: 'error',
      //     duration: 9000,
      //     isClosable: true
      //   });
      //   notificationDisplayed = true;
      // }
    } catch (error) {
      // toast({
      //   position: 'top-center',
      //   title: 'Transaction',
      //   description: 'error',
      //   status: 'failed',
      //   duration: 9000,
      //   isClosable: true,
      // });
      toast.error(error.reason);
      // toast({
      //   position: 'top-left',
      //   title: 'Wallet connect',
      //   description: 'Wallet connected successfully',
      //   status: 'error',
      //   duration: 9000,
      //   isClosable: true,
      // });
    }
  };

  // Authenticated toast
  const handleConnect = () => {
    toast.success('Wallet connected successfully.', {
      position: 'top-left',
    });
    // toast({
    //   position: 'top-left',
    //   title: 'Wallet connect',
    //   description: 'Wallet connected successfully',
    //   status: 'success',
    //   duration: 9000,
    //   isClosable: true,
    // });
  };

  const connectWallet = async () => {
    const chainId = await window.ethereum?.request({
      method: 'eth_chainId',
    });

    if (chainId != ChainId) {
      await window.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ChainId }],
      });
    }

    await window.ethereum?.request({
      method: 'wallet_requestPermissions',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });

    const accounts = await window.ethereum?.request({
      method: 'eth_requestAccounts',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });

    setCurrentAccount(accounts[0]);
    handleConnect();

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1500);
  };

  const checkIfWalletIsConnect = async () => {
    const chainId = await window.ethereum?.request({
      method: 'eth_chainId',
    });

    const accounts = await window.ethereum?.request({ method: 'eth_accounts' });

    if (chainId == ChainId && accounts.length) {
      setCurrentAccount(accounts[0]);
      // handleConnect();
    }
  };

  useEffect(() => {
    checkIfWalletIsConnect();
  }, []);

  return (
    <AirDropContext.Provider
      value={{
        connectWallet,
        currentAccount,
        claimTxn,
      }}
    >
      {children}
    </AirDropContext.Provider>
  );
};
