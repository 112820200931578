import React from 'react';
import {
  ViewCheckbox,
  ViewDropdown,
  ViewMultipleChoice,
  Viewparagraph,
  ViewShortQuestion,
  ViewUploadFile,
} from '../components/View';
import PropTypes from 'prop-types';
import useDynamicInitialValues from '../hooks/useInitialState';

function ClientFormBuilders({ formik, field, contentCount }) {
  switch (field?.type) {
    case 'text':
      return <ViewShortQuestion data={field} contentCount={contentCount} />;
    case 'paragraph':
      return <Viewparagraph data={field} placeholder={field?.question} contentCount={contentCount} />;
    case 'checkbox':
      return (
        <ViewCheckbox
          data={field}
          contentCount={contentCount}
          placeholder={field?.question}
          checkboxOptions={field?.options?.[0]?.optionItems}
        />
      );
    case 'multiplechoice':
      return (
        <ViewMultipleChoice
          data={field}
          contentCount={contentCount}
          placeholder={field?.question}
          socialMediaOptions={field?.options?.[0]?.optionItems}
        />
      );
    case 'fileupload':
      return <ViewUploadFile data={field} contentCount={contentCount} />;
    case 'dropdown':
      return (
        <ViewDropdown
          data={field}
          formik={formik}
          placeholder={field?.question}
          dropdownList={field?.options?.[0]?.optionItems}
          contentCount={contentCount}
        />
      );
    default:
      return null;
  }
}
ClientFormBuilders.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};
export default ClientFormBuilders;
