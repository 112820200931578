import React from 'react';
import './CreateAirdrop.css';
import Navbar from '../../components/Navbar/Navbar';
import CreateAirdropForm from '../../components/AirdropForm/CreateAirdropForm';
import { FormProvider } from '../../hooks/contextHook';
import Footer from '../../components/Footer/Footer';

const CreateAirdrop = () => {
  return (
    <div>
      <Navbar />
      <FormProvider>
        <CreateAirdropForm />
      </FormProvider>
      {/* <Footer/> */}
    </div>
  );
};

export default CreateAirdrop;
