import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Airdrophomecard.css';
import * as Yup from 'yup';
import { useCustomFormikContext } from '../../hooks/contextFormikHook';
import { getGeoLocation } from '../../helpers/getGeoLocation';
import processFormData from '../../helpers/formDataBuilder';
import { useCreateClaimMutation, useLoginMutation, useSignInMutation, useSignupMutation } from '../../config/Apibase';
import { useNavigate } from 'react-router-dom';
// import { FaFacebook, FaInstagram, FaLinkedin, FaTelegram, FaTwitter, FaWhatsapp } from 'react-icons/fa6';
import { useFormik } from 'formik';
// import toast from 'react-hot-toast';
import { toast } from 'react-toastify';
import AirdropDetails from '../AirdropDetails/AirdropDetails';
import SocialLinks from '../SocialLinks/SocialLinks';
import AirdropStatus from '../AirdropStatus/AirdropStatus';
import ClaimedAirdrops from '../ClaimedAirdrops/ClaimedAirdrops';
import { selectUserConnectedWallet } from '../../redux/slices/userSlice';
import { useSelector } from 'react-redux';

const Card = ({ item, id }) => {
  const [showFullContent, setShowFullContent] = useState(false);
  const userWalletAddress = useSelector(selectUserConnectedWallet);
  // const [userlocation, setUserLocation] = useState({ ipaddress: '', location: {} });
  const [currentContent, setCurrentContent] = useState(null);
  // Convert the expiration date string to a Date object
  const expirationDateTime = new Date(item.expirationDate);
  // expirationDateTime.setHours(expirationDateTime.getHours() + 19);
  expirationDateTime.setHours(expirationDateTime.getHours());

  const { formik, isSubmitting, handleSubmitCallbackFunc, onSubmitCallback } = useCustomFormikContext();

  const currentDate = new Date();
  const isExpired = expirationDateTime <= currentDate;
  const [createClaim] = useCreateClaimMutation();

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
    console.log(showFullContent, '>>>Checking Full Content');
    console.log(setShowFullContent, '>>>Checking set show full content');
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const [showSignup, setShowSignup] = useState(false);

  const handleCloseSignup = () => setShowSignup(false);
  const handleShowSignup = () => setShowSignup(true);
  const aftersignup = () => {
    handleCloseSignup();
  };

  // Connect Metamask For Signin
  const [isOpen, setIsOpen] = useState(true);
  const [signature, setSignature] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [token, setToken] = useState({});

  const [login] = useLoginMutation();
  const [signIn, data] = useSignInMutation();
  const [signup] = useSignupMutation();
  const navigate = useNavigate();
  // Login
  async function OnHandleSignIn() {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        if (isOpen) {
          setIsOpen(false);
          const currentAccount = accounts[0];
          localStorage.setItem('currentAccount', currentAccount);
          const timestamp = Date.now();
          setTimestamp(Date.now());
          const message = 'AirDrop' + timestamp;
          const response = await window.ethereum.request({
            method: 'personal_sign',
            params: [message, currentAccount],
          });
          const walletAddress = currentAccount;
          const loginResponse = await login({ walletAddress });
          if (loginResponse?.data?.message === 'User not register') {
            handleShowSignup(true);
          } else {
            const token = loginResponse?.data?.message?.token;
            localStorage.setItem('logintoken', token);
            // setShowclaimdrop(true);
            navigate('users-dashboard');
          }
        }
      } else {
        toast.error('MetaMask is not installed');
      }
    } catch (error) {
      toast.error(`Error signing message.`);
    }
  }

  const handleShow = (item) => {
    setCurrentContent(item);
    OnHandleSignIn();
    // handleShowSignup(true);
    // setShowclaimdrop(true);
  };
  const [showclaimdrop, setShowclaimdrop] = useState(false);

  const transformData = (data) => {
    const section = {
      contents: [],
    };

    for (const key in data) {
      const type = key.split('-')[0];

      if (type) {
        const content = {};
        content[type] = data[key];
        section.contents.push(content);
      }
    }

    return [section];
  };
  const handleCloseclaimdrop = () => setShowclaimdrop(false);

  const handleSubmit = async (values) => {
    const cloneValues = { ...values };
    if (Object.keys(cloneValues).includes('walletAddress')) {
      delete cloneValues.walletAddress;
    }
    const transformedData = transformData(cloneValues);

    const resobj = await getGeoLocation();
    let country = resobj?.country;
    let ipaddress = await getIpAddress();

    const form = {
      country,
      ipaddress,
      walletAddress: values?.walletAddress,
      status: 'pending',
      airdrop: item?._id,
      sections: transformedData,
    };

    let data = processFormData(form);
    // createClaim(data)
    //   .then((response) => {
    //     handleCloseclaimdrop();
    //     setShowthanksclaimdrop(true);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  useEffect(() => {
    if (!onSubmitCallback) {
      handleSubmitCallbackFunc(() => handleSubmit);
    }
  }, []);

  async function getIpAddress() {
    try {
      // Make a GET request to the ipify API
      const response = await fetch('https://api.ipify.org/?format=json');

      // Check if the request was successful (status code 200)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response
      const data = await response.json();

      // Extract and use the IP address from the response
      const ipAddress = data.ip;
      return ipAddress;
    } catch (error) {
      toast.error('Error in getting IP address');
    }
  }
  async function getLocationWithIpAddress(ipAddress) {
    // try {
    //   // const encodedParams = new URLSearchParams();
    //   // encodedParams.set('ip', ipAddress);
    //   // encodedParams.set('reverse-lookup', 'checked');

    //   // const url = 'https://community-neutrino-ip-info.p.rapidapi.com/ip-info';

    //   // const response = await fetch(url, {
    //   //   method: 'POST',
    //   //   headers: {
    //   //     'Content-Type': 'application/x-www-form-urlencoded',
    //   //     'X-RapidAPI-Key': 'f219192224mshe90d3c2a50e1f0ap1ab14fjsna04017a9e463',
    //   //     'X-RapidAPI-Host': 'community-neutrino-ip-info.p.rapidapi.com',
    //   //   },
    //   //   body: encodedParams,
    //   // });

    //   // if (!response.ok) {
    //   //   throw new Error(`HTTP error! Status: ${response.status}`);
    //   // }

    //  // const data = await response.json();
    //   return true;
    // } catch (error) {
    //   console.error('Error:', error.message);
    // }
    return 1;
  }
  const [loadingLocation, setLoadingLocation] = useState(false);
  const handleShowclaimdrop = async () => {
    setLoadingLocation(true);
    const ip = await getIpAddress();
    const locationData = await getLocationWithIpAddress(ip);
    if (locationData) {
      setUserLocation((prev) => ({
        ...prev,
        ipaddress: ip,
        location: locationData,
      }));
      handleClose();
      setShowclaimdrop(true);
      setLoadingLocation(false);
    }
  };
  const [showthanksclaimdrop, setShowthanksclaimdrop] = useState(false);

  const handleClosethanksclaimdrop = () => setShowthanksclaimdrop(false);
  const handleShowthanksclaimdrop = (e) => {
    e.preventDefault();
    handleCloseclaimdrop();
    setShowthanksclaimdrop(true);
  };

  // Share title

  const handleShareClick = () => {
    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: item?.sections?.[0]?.title,
          text: item?.sections?.[0]?.title,
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Web Share API not supported on this browser');
    }
  };

  const initialValues = {
    username: '',
    email: '',
  };

  const createdDate = new Date(item?.createdAt).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const onSubmit = async (values) => {
    const data = { ...values };

    // user Wallect address
    data.walletAddress = storedAddress;
    try {
      const response = await signup(data);
      console.log(response, '>>Checking signup res');
      if (response?.data?.error) {
        console.log('erorr', response?.data?.error);
        toast.error(response?.data?.error);
        return;
      }
      const signuptoken = response?.data?.token;
      const savesignup = localStorage.setItem('signintoken', signuptoken);
      if (signuptoken) {
        aftersignup();
        navigate('users-dashboard');
      }
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };
  const formikSignup = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const itemDescription = item?.sections?.[0]?.description || '';
  const truncatedDescription = itemDescription.split(' ').slice(0, 80).join(' ');

  //check status for airdrop
  // const [status, setStatus] = useState();
  // const endTime = new Date(expirationDateTime);
  // const currentTime = new Date();

  // const isLive = currentTime < endTime;
  // if (isLive) {
  //   setStatus(true);
  // } else {
  //   setStatus(false);
  // }
  return (
    <>
      <div>
        <h3 className="airdrop_name text-center mb-4">{item?.sections?.[0]?.title}</h3>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <AirdropDetails
            dangerouslySetInnerHTML={{ __html: showFullContent ? itemDescription : truncatedDescription }}
          />
          <div className="mt-3"></div>
          <SocialLinks item={item} />
          <div className="mb-md-3 mb-3"></div>
        </div>
        <div className="col-lg-4 mb-md-3 mb-3">
          <AirdropStatus item={item} targetDate={expirationDateTime} createdAt={createdDate} />
        </div>
        <div className="col-lg-4">
          <ClaimedAirdrops targetDate={expirationDateTime} airdropId={id} />
        </div>
      </div>
      {/* <div className="">
        <div className="col-12">
          <div className="card airdrop_home_card mb-lg-5 mb-md-4 mb-4">
            <div className="row">
              <div className="col-lg-2">
                <div>
                  <p className="offer_created_text mb-0">Offer Created</p>
                  <p className="offer_created_date">
                    {new Date(item?.createdAt).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </p>
                  
                  <p className="offer_created_text mb-0">Expiry</p>
                  <p className="offer_created_remaining_days mb-0">
                    <Timer targetDate={expirationDateTime} />{' '}
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className={` h-100 ${showFullContent ? '' : 'align-items-start'}`}>
                  
                  <div className="text-center">
                    <h5 className="airdrops_home_card_text_h mb-3 pe-lg-5">{item?.sections?.[0]?.title}</h5>
                  </div>
                  
                  <div>
                    <p
                      className="airdrops_home_card_text_p mb-0"
                      dangerouslySetInnerHTML={{ __html: showFullContent ? itemDescription : truncatedDescription }}
                    ></p>
                    {itemDescription.split(' ').length > 80 && (
                      <p className="see_more_text_btn" onClick={toggleContent}>
                        {showFullContent ? 'See Less' : 'See More'}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div
                  className={`d-flex h-100 justify-content-lg-end justify-content-center ${
                    showFullContent ? 'align-items-start' : 'align-items-start'
                  }`}
                >
                  <button
                    className={`claim_now_btn mt-lg-0 mt-md-3 mt-3 ${isExpired ? 'claim_now_btn_disabled' : ''}`}
                    onClick={() => handleShow(item?.sections?.[0]?.contents)}
                    disabled={isExpired}
                  >
                    Claim Now
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-end pb-4 pt-2">
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-1">
                    <Link to="https://www.facebook.com/ideaologytech/" target="_blank" rel="noopenner">
                      <FaFacebook fontSize={32} color="#316FF6" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-2">
                    <Link to="https://t.me/ideaologyio" target="_blank" rel="noopenner">
                      <FaTelegram fontSize={32} color="#0088cc" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-2">
                    <Link to="https://www.linkedin.com/company/ideaology-io/" target="_blank" rel="noopenner">
                      <FaLinkedin fontSize={32} color="#0077b5" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-2">
                    <Link to="https://twitter.com/ideaologyio" target="_blank" rel="noopenner">
                      <FaTwitter fontSize={32} color="#1DA1F2" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center px-2 py-2">
                    <Link to="https://www.instagram.com/ideaologyio" target="_blank" rel="noopenner">
                      <FaInstagram fontSize={32} color="rgb(191 47 156)" />
                    </Link>
                  </div>
                  <div className="wap_share_icon d-flex justify-content-center align-items-center pe-2 py-2">
                    <div className="share_heading_icon" onClick={handleShareClick}>
                      <RiShareFill fontSize={28} color="aqua" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Sign Up Modal  */}

      {/* <Modal show={showSignup} onHide={handleCloseSignup} centered>
        <div className="px-lg-4 px-md-4 px-3">
          <form onSubmit={formikSignup.handleSubmit} className="">
            <div className="text-center pt-4">
              <h5 className="sign_up_form_title mb-0">Sign Up</h5>
            </div>
            <div>
              <label htmlFor="username" className="airdrops_home_card_text_p mb-2"></label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Enter Your Username"
                className="claimdrop_input"
                onChange={formikSignup.handleChange}
                onBlur={formikSignup.handleBlur}
                value={formikSignup.values.username}
              />
              {formikSignup.touched.username && formikSignup.errors.username ? (
                <div className="signup_error">{formikSignup.errors.username}</div>
              ) : null}
            </div>
            <div className="mt-3">
              <label htmlFor="email" className=""></label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Your Email"
                className="claimdrop_input"
                onChange={formikSignup.handleChange}
                onBlur={formikSignup.handleBlur}
                value={formikSignup.values.email}
              />
              {formikSignup.touched.email && formikSignup.errors.email ? (
                <div className="signup_error">{formikSignup.errors.email}</div>
              ) : null}
            </div>
            <div className="text-center mb-4 mt-4">
              <button type="submit" className="submit_signup_form_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal> */}

      {/* Location Modal  */}

      {/* <Modal show={show} onHide={handleClose} size="lg" centered>
        <div className="location_modal_space">
          <div className="row">
            <div className="col-3">
              <div className="d-flex align-items-center h-100 justify-content-center">
                <img src={locationiconimg} alt="location_img" className="location_img_size" />
              </div>
            </div>
            <div className="col-9">
              <div>
                <h5 className="share_live_location">Share your live Location</h5>
                <p className="shared_location_p">
                  Shared location will be safe. Company is responsible for the privacy of your location.
                </p>
                <button className="share_live_location_btn" onClick={handleShowclaimdrop}>
                  {loadingLocation ? (
                    <div className="load-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  ) : (
                    'Share Live Location'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  );
};
Card.propTypes = {
  item: PropTypes.any,
};
export default Card;
