import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loggedIn: false,
    user: null,
    userConnectedWallet: null,
    accessToken: null,
  },
  reducers: {
    addUserWallet: (state, action) => {
      state.userConnectedWallet = action.payload;
    },
    removeUserWallet: (state) => {
      state.userConnectedWallet = null;
    },
    loginSuccess: (state, action) => {
      state.loggedIn = true;
      state.userConnectedWallet = action.payload?.user?.walletAddress;
      state.user = action.payload?.user;
      state.accessToken = action.payload?.tokens?.access?.token;
    },
    updateUser: (state, action) => {
      state.loggedIn = true;
      let token = state.user?.token;
      let user = { ...action.payload, token };
      state.user = user;
      // state.user.token = token;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.user = null;
      state.accessToken = null;
      state.userConnectedWallet = null;
    },
  },
});

export const { loginSuccess, logout, updateUser, addUserWallet, removeUserWallet } = userSlice.actions;

// Selectors
export const selectUser = (state) => state.user.user;
export const selectLoggedIn = (state) => state.user.loggedIn;
export const selectToken = (state) => state.user.accessToken;
export const selectUserConnectedWallet = (state) => state.user.userConnectedWallet;
export const selectAdminWallet = (state) => state.user?.user?.walletAddress;
export const selectIsUserWalletConnected = (state) => (state.user.userConnectedWallet ? true : false);

export default userSlice.reducer;
