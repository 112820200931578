import React, { createContext, useContext, useState } from 'react';
import useCustomFormik from './useFormik';
// Create a context for your formik values
const CustomFormikContext = createContext();
// CustomFormikProvider component that uses useCustomFormik hook
export const CustomFormikProvider = ({ children }) => {
  const [onSubmitCallback, setOnSubmitCallback] = useState(null);
  const { formik, isSubmitting, resetClaimForm } = useCustomFormik(onSubmitCallback);
  const handleSubmitCallbackFunc = (func) => {
    setOnSubmitCallback(func);
  };
  return (
    <CustomFormikContext.Provider value={{ formik, isSubmitting, onSubmitCallback, handleSubmitCallbackFunc, resetClaimForm }}>
      {children}
    </CustomFormikContext.Provider>
  );
};

export const useCustomFormikContext = () => {
  const context = useContext(CustomFormikContext);

  if (!context) {
    throw new Error('useCustomFormikContext must be used within a CustomFormikProvider');
  }

  return context;
};
