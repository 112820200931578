import React, { useState, useEffect } from 'react';
import '../CreateAirdropForm.css';
import crossiconimg from '../../../assets/images/crossIconImage.png';
import PropTypes from 'prop-types';
import { useFormContext } from '../../../hooks/contextHook';
const Dropdown = ({ onInputChange, field }) => {
  const [dropdowns, setDropdowns] = useState(['Dropdown 1']);
  const [dropdownModified, setDropdownModified] = useState(false);
  const { updateOptionItem, deleteOptionItem } = useFormContext();
  const option = field?.options[0];
  const fieldId = field?.id;
  const addDropdown = () => {
    updateOptionItem({ optionId: option?.id, updateitem: { name: 'Dropdown', type: 'dropdown' } });
  };

  const handleInputChange = (item, newName) => {
    updateOptionItem({ optionId: option?.id, updateitem: { ...item, name: newName } });
  };

  const removeDropdown = (item) => {
    deleteOptionItem({ optionItemId: item?.id });
  };

  useEffect(() => {
    if (dropdownModified) {
      onInputChange({
        dropdown: dropdowns,
        numDropdown: dropdowns.length,
      });
      setDropdownModified(false);
    }
  }, [dropdownModified, dropdowns, onInputChange]);

  return (
    <div>
      {option?.optionItems?.map((dropdown, index) => (
        <div key={index} className="mb-2 mt-2 d-flex align-items-center ps-0 w-100">
          <label htmlFor={`dropdown${index + 1}`} className="">
            <span className="me-3 dropdown_count_span">{index + 1}.</span>
          </label>
          <input
            type="text"
            id={`dropdown${index + 1}`}
            name={`dropdown${index + 1}`}
            value={dropdown?.name}
            onChange={(e) => handleInputChange(dropdown, e.target.value)}
            className="options_input_field"
          />
          <div>
            <img
              src={crossiconimg}
              alt="Cross"
              width="15px"
              className="ms-4 cross_icon_cursor"
              onClick={() => removeDropdown(dropdown)}
            ></img>
          </div>
        </div>
      ))}
      <div className="mb-2 mt-2 d-flex align-items-center">
        <span className="option_label_text pt-2">
          <span className="text_blue_color" onClick={addDropdown}>
            Add &quot;another&quot; Dropdown
          </span>
        </span>
      </div>
      <div className="border-bottom-form-hr mt-3"></div>
    </div>
  );
};

Dropdown.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default Dropdown;
