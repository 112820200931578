import React from 'react';
import '../AirdropCards/Airdrophomecard.css';
import useCustomFormik from '../../hooks/useFormik';
import { useCustomFormikContext } from '../../hooks/contextFormikHook';
const Viewparagraph = ({ data, placeholder, contentCount }) => {
  const { formik } = useCustomFormikContext();
  return (
    <div>
      <div>
        <label htmlFor="paragraph" className='airdrops_home_card_text_p mb-2'><span className='fw-bold pe-1'>{data?.question}</span>{data?.isRequired && <span style={{ color: 'red' }}>*</span>}
</label>
        <textarea
          id="paragraph"
          name={placeholder + '-c-' + contentCount}
          onChange={formik.handleChange}
          placeholder={placeholder}
          required={data?.isRequired}
          rows="5"
          className="claimdrop_textarea mb-3"
        />
      </div>
    </div>
  );
};

export default Viewparagraph;
