import React from 'react';
import '../AirdropCards/Airdrophomecard.css';
import useCustomFormik from '../../hooks/useFormik';
import { useCustomFormikContext } from '../../hooks/contextFormikHook';
const ViewDropdown = ({ placeholder, dropdownList, contentCount, data }) => {
  const { formik } = useCustomFormikContext();

  return (
    <div className="py-3">
      <label htmlFor="viewdropdown" className="airdrops_home_card_text_p mb-2">
       <span className='fw-bold pe-1'> {placeholder}</span>{data?.isRequired && <span style={{ color: 'red' }}>*</span>}

      </label>
      <select
        id="viewdropdown"
        name={'dropdown' + '-c-' + contentCount}
        onChange={formik.handleChange}
        required={data?.isRequired}
        // value={formik.values.dropdown}
        className="view_dropdown_select"
      >
        {dropdownList?.map((item) => (
          <option key={item?.id} value={`${item?.name}`}>
            {item?.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ViewDropdown;
