import React, { useEffect, useState } from 'react';
import '../AirdropCards/Airdrophomecard.css';
import { useCustomFormikContext } from '../../hooks/contextFormikHook';

const ViewCheckbox = ({ data, placeholder, checkboxOptions, contentCount }) => {
  const { formik } = useCustomFormikContext();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;

    if (event.target.checked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, checkboxValue]);
    } else {
      setSelectedCheckboxes((prevSelected) =>
        prevSelected.filter((value) => value !== checkboxValue)
      );
    }

    // formik.setFieldValue('checkbox' + '-c-' + contentCount, selectedCheckboxes.toString())

  };

  useEffect(() => {
    formik.setFieldValue('checkbox' + '-c-' + contentCount, selectedCheckboxes.toString())
  }, [JSON.stringify(selectedCheckboxes)])

  const isInputRequired = selectedCheckboxes.length === 0 && data?.isRequired;

  return (
    <div>
      <p className="airdrops_home_card_text_p mb-2">
        <span className='fw-bold pe-1'>{placeholder}</span>
        {isInputRequired && <span style={{ color: 'red' }}>*</span>}
      </p>
      {checkboxOptions.map((option) => (
        <div className="form-group-checkbox" key={option.id}>
          <input
            type="checkbox"
            name={'checkbox' + '-c-' + contentCount}
            required={isInputRequired}
            id={option.id}
            value={option.name?.split(",")}
            onChange={handleCheckboxChange}
            multiple
          />
          <label htmlFor={option.id}>
            <span className="airdrops_home_card_label_text ps-2">{option.name}</span>
          </label>
        </div>
      ))}
    </div>
  );
};

export default ViewCheckbox;
