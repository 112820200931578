import React, { useState, useEffect } from 'react';
import Timer from '../AirdropCards/Timer';
import timeup from '../../assets/images/check-time-icon.svg';
import { Modal } from 'react-bootstrap';
import locationiconimg from '../../assets/images/locationiconimg.png';
import thanksimg from '../../assets/images/thanksimg.png';
import { useCustomFormikContext } from '../../hooks/contextFormikHook';
import { useCreateClaimMutation, useLoginMutation, useSignInMutation, useSignupMutation } from '../../config/Apibase';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import '../SocialLinks/SocialLinks.css';
import './AirdropStatus.css';
import NewTimer from '../AirdropCards/NewTimer';
import { addUserWallet, selectUserConnectedWallet } from '../../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const AirdropStatus = ({ item, targetDate, createdAt }) => {
  const userWalletAddress = useSelector(selectUserConnectedWallet);
  const [showFullContent, setShowFullContent] = useState(false);
  const [userlocation, setUserLocation] = useState({ ipaddress: '', location: {} });
  const [currentContent, setCurrentContent] = useState(null);
  // Convert the expiration date string to a Date object
  const expirationDateTime = new Date(item.expirationDate);
  expirationDateTime.setHours(expirationDateTime.getHours() + 19);

  const { formik, isSubmitting, handleSubmitCallbackFunc, onSubmitCallback } = useCustomFormikContext();

  const currentDate = new Date();
  const isExpired = expirationDateTime <= currentDate;
  const [createClaim] = useCreateClaimMutation();

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
    console.log(showFullContent, '>>>Checking Full Content');
    console.log(setShowFullContent, '>>>Checking set show full content');
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const [showSignup, setShowSignup] = useState(false);

  const handleCloseSignup = () => setShowSignup(false);
  const handleShowSignup = () => setShowSignup(true);
  const aftersignup = () => {
    handleCloseSignup();
  };

  // Connect Metamask For Signin
  const [isOpen, setIsOpen] = useState(true);
  const [signature, setSignature] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [token, setToken] = useState({});

  const [login] = useLoginMutation();
  const [signIn, data] = useSignInMutation();
  const [signup] = useSignupMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Login
  // async function OnHandleSignIn() {
  //   try {
  //     if (window.ethereum) {
  //       const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

  //       if (isOpen) {
  //         setIsOpen(false);
  //         const currentAccount = accounts[0];
  //         localStorage.setItem('currentAccount', currentAccount);
  //         const timestamp = Date.now();
  //         setTimestamp(Date.now());
  //         const message = 'AirDrop' + timestamp;
  //         const response = await window.ethereum.request({
  //           method: 'personal_sign',
  //           params: [message, currentAccount],
  //         });
  //         const walletAddress = currentAccount;
  //         const loginResponse = await login({ walletAddress });
  //         if (loginResponse?.data?.message === 'User not register') {
  //           handleShowSignup(true);
  //         } else {
  //           const token = loginResponse?.data?.message?.token;
  //           localStorage.setItem('logintoken', token);
  //           // setShowclaimdrop(true);
  //           navigate('users-dashboard');
  //         }
  //       }
  //     } else {
  //       toast.error('MetaMask is not installed');
  //     }
  //   } catch (error) {
  //     toast.error(`Error signing message: ${error}`);
  //   }
  // }

  const OnHandleSignIn = async () => {
    try {
      if (window.ethereum) {
        const connect = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (connect?.length) {
          dispatch(addUserWallet(connect[0]));
          navigate('/users-dashboard');
        }
      } else {
        toast.error('MetaMask is not installed');
      }
    } catch (error) {
      toast.error(`Error in signing message.`);
    }
  };

  const handleShow = (item) => {
    setCurrentContent(item);
    if (userWalletAddress) {
      navigate('/users-dashboard');
    } else {
      OnHandleSignIn();
    }

    // handleShowSignup(true);
    // setShowclaimdrop(true);
  };
  const [showclaimdrop, setShowclaimdrop] = useState(false);

  const transformData = (data) => {
    const section = {
      contents: [],
    };

    for (const key in data) {
      const type = key.split('-')[0];

      if (type) {
        const content = {};
        content[type] = data[key];
        section.contents.push(content);
      }
    }

    return [section];
  };
  const handleCloseclaimdrop = () => setShowclaimdrop(false);

  const handleSubmit = async (values) => {
    const cloneValues = { ...values };
    if (Object.keys(cloneValues).includes('walletAddress')) {
      delete cloneValues.walletAddress;
    }
    const transformedData = transformData(cloneValues);

    const resobj = await getGeoLocation();
    let country = resobj?.country;
    let ipaddress = await getIpAddress();

    const form = {
      country,
      ipaddress,
      walletAddress: values?.walletAddress,
      status: 'pending',
      airdrop: item?._id,
      sections: transformedData,
    };

    let data = processFormData(form);
    // createClaim(data)
    //   .then((response) => {
    //     handleCloseclaimdrop();
    //     setShowthanksclaimdrop(true);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  useEffect(() => {
    if (!onSubmitCallback) {
      handleSubmitCallbackFunc(() => handleSubmit);
    }
  }, []);

  async function getIpAddress() {
    try {
      // Make a GET request to the ipify API
      const response = await fetch('https://api.ipify.org/?format=json');

      // Check if the request was successful (status code 200)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the JSON response
      const data = await response.json();

      // Extract and use the IP address from the response
      const ipAddress = data.ip;
      return ipAddress;
    } catch (error) {
      console.error('Error:', error.message);
    }
  }
  async function getLocationWithIpAddress(ipAddress) {
    // try {
    //   // const encodedParams = new URLSearchParams();
    //   // encodedParams.set('ip', ipAddress);
    //   // encodedParams.set('reverse-lookup', 'checked');

    //   // const url = 'https://community-neutrino-ip-info.p.rapidapi.com/ip-info';

    //   // const response = await fetch(url, {
    //   //   method: 'POST',
    //   //   headers: {
    //   //     'Content-Type': 'application/x-www-form-urlencoded',
    //   //     'X-RapidAPI-Key': 'f219192224mshe90d3c2a50e1f0ap1ab14fjsna04017a9e463',
    //   //     'X-RapidAPI-Host': 'community-neutrino-ip-info.p.rapidapi.com',
    //   //   },
    //   //   body: encodedParams,
    //   // });

    //   // if (!response.ok) {
    //   //   throw new Error(`HTTP error! Status: ${response.status}`);
    //   // }

    //  // const data = await response.json();
    //   return true;
    // } catch (error) {
    //   console.error('Error:', error.message);
    // }
    return 1;
  }
  const [loadingLocation, setLoadingLocation] = useState(false);
  const handleShowclaimdrop = async () => {
    setLoadingLocation(true);
    const ip = await getIpAddress();
    const locationData = await getLocationWithIpAddress(ip);
    if (locationData) {
      setUserLocation((prev) => ({
        ...prev,
        ipaddress: ip,
        location: locationData,
      }));
      handleClose();
      setShowclaimdrop(true);
      setLoadingLocation(false);
    }
  };
  const [showthanksclaimdrop, setShowthanksclaimdrop] = useState(false);

  const handleClosethanksclaimdrop = () => setShowthanksclaimdrop(false);
  const handleShowthanksclaimdrop = (e) => {
    e.preventDefault();
    handleCloseclaimdrop();
    setShowthanksclaimdrop(true);
  };

  // Share title

  const handleShareClick = () => {
    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: item?.sections?.[0]?.title,
          text: item?.sections?.[0]?.title,
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Web Share API not supported on this browser');
    }
  };

  const initialValues = {
    username: '',
    email: '',
  };

  const createdDate = new Date(item?.createdAt).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const validationSchema = Yup.object({
    username: Yup.string().required('Username is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const onSubmit = async (values) => {
    const data = { ...values };
    // user Wallect address
    data.walletAddress = userWalletAddress;
    try {
      const response = await signup(data);
      console.log(response, '>>Checking signup res');
      if (response?.data?.error) {
        console.log('erorr', response?.data?.error);
        toast.error('Error in signing up');
        return;
      }
      const signuptoken = response?.data?.token;
      const savesignup = localStorage.setItem('signintoken', signuptoken);
      if (signuptoken) {
        aftersignup();
        navigate('users-dashboard');
      }
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };
  const formikSignup = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  const itemDescription = item?.sections?.[0]?.description || '';
  const truncatedDescription = itemDescription.split(' ').slice(0, 80).join(' ');

  //AirdropStatus logic start
  const endTime = new Date(targetDate);
  const formattedEndTime = endTime.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const time = Date.now();
  const date = new Date(time);
  const formattedDate = date.toLocaleString();

  const isLive = date < endTime;

  //AirdropStatus logic ends

  return (
    <>
      <div className="">
        <div className="card status_card airdrop_card">
          {isLive ? (
            <p className="status_text"> Live</p>
          ) : (
            <img
              src={timeup}
              className="status_text status_img pb-lg-5 mb-lg-5 mt-lg-5"
              alt="timeup svg"
              style={{
                width: '80px',
                marginTop: '30px',
                '@media (min-width: 992px) and (max-width: 1199px)': {
                  width: '45px',
                  marginTop: '40px',
                },
              }}
            />
          )}
          <div className="timer text-center">
            <NewTimer targetDate={targetDate} />
          </div>
          <div className="airdrop_time px-4">
            <div className="start_end_time d-flex">
              <p>AirDrop Start Date</p>
              <p className="ms-auto">{createdAt}</p>
            </div>
            <hr />
            <div className="start_end_time d-flex">
              <p>AirDrop End Date</p>
              <p className="ms-auto">{formattedEndTime}</p>
            </div>
          </div>
          <div>
            <button
              className={`claim_now_button w-50 ${isLive ? '' : 'claim_now_btn_disabled'}`}
              onClick={() => handleShow(item?.sections?.[0]?.contents)}
            >
              Claim Now
            </button>
          </div>
        </div>
      </div>

      {/* Sign Up Modal  */}

      <Modal show={showSignup} onHide={handleCloseSignup} centered>
        <div className="px-lg-4 px-md-4 px-3">
          <form onSubmit={formikSignup.handleSubmit} className="">
            <div className="text-center pt-4">
              <h5 className="sign_up_form_title mb-0">Sign Up</h5>
            </div>
            <div>
              <label htmlFor="username" className="airdrops_home_card_text_p mb-2"></label>
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Enter Your Username"
                className="claimdrop_input"
                onChange={formikSignup.handleChange}
                onBlur={formikSignup.handleBlur}
                value={formikSignup.values.username}
              />
              {formikSignup.touched.username && formikSignup.errors.username ? (
                <div className="signup_error">{formikSignup.errors.username}</div>
              ) : null}
            </div>
            <div className="mt-3">
              <label htmlFor="email" className=""></label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Your Email"
                className="claimdrop_input"
                onChange={formikSignup.handleChange}
                onBlur={formikSignup.handleBlur}
                value={formikSignup.values.email}
              />
              {formikSignup.touched.email && formikSignup.errors.email ? (
                <div className="signup_error">{formikSignup.errors.email}</div>
              ) : null}
            </div>
            <div className="text-center mb-4 mt-4">
              <button type="submit" className="submit_signup_form_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Location Modal  */}

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <div className="location_modal_space">
          <div className="row">
            <div className="col-3">
              <div className="d-flex align-items-center h-100 justify-content-center">
                <img src={locationiconimg} alt="location_img" className="location_img_size" />
              </div>
            </div>
            <div className="col-9">
              <div>
                <h5 className="share_live_location">Share your live Location</h5>
                <p className="shared_location_p">
                  Shared location will be safe. Company is responsible for the privacy of your location.
                </p>
                <button className="share_live_location_btn" onClick={handleShowclaimdrop}>
                  {loadingLocation ? (
                    <div className="load-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  ) : (
                    'Share Live Location'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AirdropStatus;
