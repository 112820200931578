import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode'; // Assuming you're using jwtDecode
import { useSelector } from 'react-redux';
import { selectToken } from '../../redux/slices/userSlice';

function useToken() {
  const token = useSelector(selectToken);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Indicate token verification is in progress

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000; // Convert expiration to milliseconds
        const currentTime = Date.now();
        setIsValid(currentTime < expirationTime); // Check expiration
      } catch (error) {
        console.error('Error decoding token:', error);
      } finally {
        setIsLoading(false); // Token verification completed
      }
    } else {
      setIsValid(false);
      setIsLoading(false); // No token found
    }
  }, [token]); // Empty dependency array to run only once

  return { token, isValid, isLoading };
}

export default useToken;
