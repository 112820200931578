import React from 'react';
import '../AirdropCards/Airdrophomecard.css';
import { useCustomFormikContext } from '../../hooks/contextFormikHook';

const ViewMultipleChoice = ({ data, placeholder, socialMediaOptions, contentCount }) => {
  const { formik } = useCustomFormikContext();
  console.log(data, ">>checking data field")
  return (
    <div>
      <p className="airdrops_home_card_text_p mb-2"><span className='fw-bold pe-1'>{placeholder}</span>{data?.isRequired && <span style={{ color: 'red' }}>*</span>}
      </p>

      {socialMediaOptions.map((option) => (
        <div className="form-check mb-2" key={option?.id}>
          <input
            className="form-check-input"
            type="radio"
            name={'multiplechoice' + '-c-' + contentCount}
            id={option?.id}
            value={option.name}
            required={data?.isRequired}
            onChange={formik.handleChange}
          />
          <label className="form-check-label ps-2 airdrops_home_card_label_text" htmlFor={option?.id}>
            {option?.name}
          </label>
        </div>
      ))}
    </div>
  );
};

export default ViewMultipleChoice;
