import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const useForm = ({ setForm, sections, contents, fields, options, form, fileOptions, optionItems }) => {
  // Utility function to add or edit a form
  const updateForm = (newName = 'Form') => {
    // If the form has an empty name, do nothing
    if (!newName.trim()) {
      return;
    }

    // Check if the form is a new form (no ID assigned yet)
    if (!form.id) {
      // Adding a new form
      setForm({ id: uuidv4(), name: newName });
    } else {
      // Editing an existing form
      setForm({ ...form, name: newName });
    }
  };

  const getFormatedForm = () => {
    if (!form?.id) {
      // If the form with the provided ID doesn't exist, return null or handle as needed
      return null;
    }

    const embeddedForm = {
      form,
      sections: sections.map((section) => ({
        ...section,
        contents: contents
          .filter((item) => item.sectionId === section.id)
          .map((item) => ({
            ...item,
            fields: fields
              .filter((field) => field.contentId === item.id)
              .map((field) => ({
                ...field,
                options: options
                  .filter((option) => option?.fieldId === field.id)
                  .map((option) => ({
                    ...option,
                    optionItems: optionItems?.filter((op) => op.optionId === option.id),
                  })),
                // fileOptions: fileOptions.filter(
                //   (option) => option?.fieldId === field.id && field.type === 'fileupload',
                // ),
              })),
          })),
      })),
    };

    return embeddedForm;
  };

  return { updateForm, getFormatedForm };
};

export default useForm;
