import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { logout, selectIsUserWalletConnected } from './redux/slices/userSlice';
import useToken from './pages/Airdropsdata/useToken';

const PrivateRoute = ({ element: Component, ...rest }) => {
  const isUserConnected = useSelector(selectIsUserWalletConnected);

  return isUserConnected ? Component : <Navigate to="/" />;
};

export default PrivateRoute;
