import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { initialField, initialOptions } from './contextHook';

const useContents = ({ contents, setContents, fields, options, setOptions, setFields }) => {
  // Utility function to add or edit content
  const updateContent = (contentId = '', newSectionId) => {
    // If the content ID is provided, check if the content exists
    const existingContent = contents.find((item) => item.id === contentId);

    if (existingContent) {
      // Editing an existing content
      const updatedContent = contents.map((item) =>
        item.id === contentId ? { ...item, sectionId: newSectionId } : item,
      );
      setContents(updatedContent);
    } else {
      // Adding a new content
      const content = { id: uuidv4(), sectionId: newSectionId };
      const field = {
        ...initialField,
        id: uuidv4(),
        contentId: content?.id,
      };
      setContents((prevContent) => [...prevContent, content]);
      setFields((prevFields) => [...prevFields, field]);

      setOptions((prevOptions) => [
        ...prevOptions,
        {
          ...initialOptions,
          id: uuidv4(),
          fieldId: field?.id,
        },
      ]);
    }
  };
  // Utility function to delete a section and its associated fields and options
  const deleteConent = (contentId) => {
    // Filter out the section to be deleted
    const updatedContents = contents.filter((content) => content.id !== contentId);
    setContents(updatedContents);

    // Filter out the fields associated with the deleted section
    const updatedFields = fields.filter((field) => updatedContents.some((content) => content.id === field.contentId));
    setFields(updatedFields);

    // Filter out the options associated with the deleted fields
    const updatedOptions = options.filter((option) => updatedFields.some((field) => field.id === option?.fieldId));
    setOptions(updatedOptions);
  };

  return { updateContent, deleteConent };
};

export default useContents;
