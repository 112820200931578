import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RiShareFill, RiWhatsappFill } from 'react-icons/ri';
import { FaFacebook, FaInstagram, FaLinkedin, FaTelegram, FaTwitter, FaWhatsapp } from 'react-icons/fa6';
import './SocialLinks.css';

const SocialLinks = ({item}) => {
  const handleShareClick = () => {
    // Check if the Web Share API is supported
    if (navigator.share) {
      navigator
        .share({
          title: item?.sections?.[0]?.title,
          text: item?.sections?.[0]?.title,
          url: window.location.href,
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      console.log('Web Share API not supported on this browser');
    }
  };
  return (
    <div className="card airdrop_card" >
      <div className="d-flex flex-column justify-content-center text-center pb-4 pt-2">
        <div className="social_link_card d-flex align-items-center justify-content-center">
          <div className="wap_share_icon px-2">
            <Link to="https://www.facebook.com/ideaologytech/" target="_blank" rel="noopenner">
              <FaFacebook fontSize={32} color="#316FF6" />
            </Link>
          </div>
          <div className="wap_share_icon  px-2">
            <Link to="https://t.me/ideaologyio" target="_blank" rel="noopenner">
              <FaTelegram fontSize={32} color="#0088cc" />
            </Link>
          </div>
          <div className="wap_share_icon  px-2">
            <Link to="https://www.linkedin.com/company/ideaology-io/" target="_blank" rel="noopenner">
              <FaLinkedin fontSize={32} color="#0077b5" />
            </Link>
          </div>
          <div className="wap_share_icon px-2">
            <Link to="https://twitter.com/ideaologyio" target="_blank" rel="noopenner">
              <FaTwitter fontSize={32} color="#1DA1F2" />
            </Link>
          </div>
          <div className="wap_share_icon px-2">
            <Link to="https://www.instagram.com/ideaologyio" target="_blank" rel="noopenner">
              <FaInstagram fontSize={32} color="rgb(191 47 156)" />
            </Link>
          </div>
          <div className="wap_share_icon px-2" onClick={handleShareClick}>
            <RiShareFill fontSize={28} color="aqua" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialLinks;
