import { v4 as uuidv4 } from 'uuid';

const useOptionItem = ({ optionItems, setOptionItem, options }) => {
  const updateOptionItem = ({ optionId, updateitem }) => {
    // Verify if optionId exists in the options array
    const existingOption = options.find((opt) => opt.id === optionId);

    if (!existingOption) {
      console.error(`Option with id ${optionId} not found.`);
      return;
    }

    if (updateitem?.id) {
      const updatedOptions = optionItems.map((opt) => (opt.id === updateitem.id ? { ...opt, ...updateitem } : opt));

      setOptionItem(updatedOptions);
    } else {
      // Create a new optionItem as a child of the parent option by optionId
      const newOptionItem = {
        id: uuidv4(),
        optionId,
        ...updateitem,
      };
      const cloneItems = [...optionItems];
      const cloneSameOptionItems = cloneItems
        .filter((i) => i.optionId === optionId)
        .filter((i) => i.type === updateitem?.type);
      const cloneOtherOptionItems = cloneItems.filter((i) => i.optionId !== optionId);

      setOptionItem([...cloneSameOptionItems, ...cloneOtherOptionItems, newOptionItem]);
    }
  };

  const resetOptionItems = ({ optionId, type }) => {
    const cloneItems = [...optionItems];
    const cloneSameOptionItems = cloneItems.filter((i) => i.optionId === optionId).filter((i) => i.type === type);
    const cloneOtherOptionItems = cloneItems.filter((i) => i.optionId !== optionId);

    setOptionItem([...cloneSameOptionItems, ...cloneOtherOptionItems]);
  };

  const deleteOptionItem = ({ optionItemId }) => {
    const clone = [...optionItems].filter((i) => i.id !== optionItemId);
    setOptionItem([...clone]);
  };

  return { updateOptionItem, resetOptionItems, deleteOptionItem };
};

export default useOptionItem;
