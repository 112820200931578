import React, { useState, useEffect } from 'react';
import './Timer.css';

const CountdownTracker = ({ label, value }) => {
  const formatValue = (val) => {
    return val < 10 ? `0${val}` : val;
  };

  const [currentValue, setCurrentValue] = useState(formatValue(value));

  useEffect(() => {
    setCurrentValue(formatValue(value));
  }, [value]);

  return (
    <span className={`flip-clock__piece ${label}`}>
      <div className="flip-clock__slot">
        <div className="timer_label">{label}</div>
        <div className="card_timer">
          <div className="card_top">{currentValue}</div>
        </div>
      </div>
    </span>
  );
};

const NewTimer = ({ targetDate }) => {
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const targetTime = new Date(targetDate).getTime() - 5 * 60 * 60 * 1000;
    const timeDifference = targetTime - now;

    if (timeDifference <= 0) {
      // Timer expired
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: true,
      };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
      expired: false,
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const { days, hours, minutes, seconds, expired } = timeRemaining;

  return (
    <div>
      {expired ? (
        <div className="timer_expire_text">AirDrop Ended!</div>
      ) : (
        <div className="clock">
          <CountdownTracker label="Days" value={days} />
          <CountdownTracker label="Hours" value={hours} />
          <CountdownTracker label="Minutes" value={minutes} />
          <CountdownTracker label="Seconds" value={seconds} />
        </div>
      )}
    </div>
  );
};

export default NewTimer;
