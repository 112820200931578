import React, { useState, useEffect } from 'react';
import crossiconimg from "../../../assets/images/crossIconImage.png";
import PropTypes from 'prop-types';
const Multiplechoicegrid = ({ onInputChange }) => {
  const [rows, setRows] = useState(['Row 1']);
  const [columns, setColumns] = useState(['Column 1']);
  const [hasChanges, setHasChanges] = useState(false); // New state variable

  const addRow = () => {
    setRows([...rows, `Row ${rows.length + 1}`]);
    setHasChanges(true); // Set hasChanges to true when a row is added
  };

  const handleRowInputChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index] = value;
    setRows(updatedRows);
    setHasChanges(true); // Set hasChanges to true when a row is updated
  };

  const removeRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
    setHasChanges(true); // Set hasChanges to true when a row is removed
  };

  const addColumn = () => {
    setColumns([...columns, `Column ${columns.length + 1}`]);
    setHasChanges(true); // Set hasChanges to true when a column is added
  };

  const handleColumnInputChange = (index, value) => {
    const updatedColumns = [...columns];
    updatedColumns[index] = value;
    setColumns(updatedColumns);
    setHasChanges(true); // Set hasChanges to true when a column is updated
  };

  const removeColumn = (index) => {
    const updatedColumns = columns.filter((_, i) => i !== index);
    setColumns(updatedColumns);
    setHasChanges(true); // Set hasChanges to true when a column is removed
  };

  useEffect(() => {
    if (hasChanges) {
      onInputChange({ rows, columns });
      setHasChanges(false); // Reset hasChanges after calling onInputChange
    }
  }, [rows, columns, onInputChange, hasChanges]);

  return (
    <div>
      <div className="row pt-2">
        <div className="col-lg-6 col-12">
          <p className="choice_grid_p mb-2">Rows</p>
          {rows.map((row, index) => (
            <div key={index} className="mb-2 mt-2 d-flex align-items-center ps-0 w-100">
              <label htmlFor={`row${index + 1}`} className=''>
                <span className='me-3 dropdown_count_span'>{index + 1}.</span>
              </label>
              <input
                type="text"
                id={`row${index + 1}`}
                name={`row${index + 1}`}
                value={row}
                onChange={(e) => handleRowInputChange(index, e.target.value)}
                className='options_input_field'
              />
              <div>
                <img
                  src={crossiconimg}
                  alt='Cross'
                  width="15px"
                  className='ms-4 cross_icon_cursor'
                  onClick={() => removeRow(index)}
                ></img>
              </div>
            </div>
          ))}
          <div className="mb-2 mt-2 d-flex align-items-center">
            <span className="option_label_text pt-2">
              <span className="text_blue_color" onClick={addRow}>Add &quot;another&quot; Row</span>
            </span>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <p className="choice_grid_p mb-2">Columns</p>
          {columns.map((column, index) => (
            <div key={index} className="mb-2 mt-2 d-flex align-items-center ps-0 w-100">
              <label htmlFor={`column${index + 1}`} className=''>
                <span className='me-3 dropdown_count_span'>{index + 1}.</span>
              </label>
              <input
                type="text"
                id={`column${index + 1}`}
                name={`column${index + 1}`}
                value={column}
                onChange={(e) => handleColumnInputChange(index, e.target.value)}
                className='options_input_field'
              />
              <div>
                <img
                  src={crossiconimg}
                  alt='Cross'
                  width="15px"
                  className='ms-4 cross_icon_cursor'
                  onClick={() => removeColumn(index)}
                ></img>
              </div>
            </div>
          ))}
          <div className="mb-2 mt-2 d-flex align-items-center">
            <span className="option_label_text pt-2">
              <span className="text_blue_color" onClick={addColumn}>Add &quot;another&quot; Column</span>
            </span>
          </div>
        </div>
      </div>
      <div className="border-bottom-form-hr mt-3"></div>
    </div>
  );
};

Multiplechoicegrid.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default Multiplechoicegrid;
