export function formatDate(inputDateString) {
    const inputDate = new Date(inputDateString);

    const year = inputDate.getUTCFullYear();
    const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(inputDate.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}


