import React, { useEffect, useRef, useState } from 'react';
import './CreateAirdropForm.css';
import deleteIconImage from '../../assets/images/deleteImage.png';
import Icon1 from '../../assets/images/Icon1.png';
import Icon2 from '../../assets/images/Icon2.png';
import Icon3 from '../../assets/images/Icon3.png';
import Icon4 from '../../assets/images/Icon4.png';
import Icon5 from '../../assets/images/Icon5.png';
import Icon6 from '../../assets/images/Icon6.png';
import arrowdown from '../../assets/images/arrowdown.png';
import PropTypes from 'prop-types';
import FormBuilders from '../../helpers/formBuilders';
import { useFormContext } from '../../hooks/contextHook';
import { useLocation } from 'react-router-dom';
const DuplicateForm = ({ id, content, index, isRequired, onCheckboxChange, onDelete, onFormChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const field = content?.fields[0];
  const option = field?.options[0];
  //const [componets, setCompoenets] = useState([]);
  const { updateField, deleteConent, updateOption, resetOptionItems, updateOptionItem } = useFormContext();
  const [selectedOption, setSelectedOption] = useState('');
  const [formData, setFormData] = useState({});
  const handleCheckboxChange = () => {
    if (field) {
      updateField({ ...field, isRequired: !field?.isRequired });
    }
  };
  const location = useLocation()

  const selectRef = useRef(null);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleDeleteClick = () => {
    deleteConent(content?.id);
  };
  const [question, setQuestion] = useState('');

  const handleFormInputChange = (section, data) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [section]: data,
    }));
    onFormChange({
      ...formData,
      [section]: data,
      question: question,
    });
  };
  const handleQuestionChange = (e) => {
    const question = e.target.value;
    if (field) {
      updateField({ ...field, question });
    }
  };
  // console.log(componets, ">>Checking components");
  const optionsArray = [
    { label: 'Short Answer', type: 'text', icon: Icon1 },
    { label: 'Paragraph', type: 'paragraph', icon: Icon2 },
    { label: 'Multiple Choice', type: 'multiplechoice', icon: Icon3 },
    { label: 'Check boxes', type: 'checkbox', icon: Icon4 },
    { label: 'Dropdown', type: 'dropdown', icon: Icon5 },
    { label: 'File Upload', type: 'fileupload', icon: Icon6 },
    // { label: 'Linear Scale', type: 'linearscale', icon: Icon7 },
    // { label: 'Multiple choice grid', type: 'multiplechoicegrid', icon: Icon8 },
    // { label: 'Checkbox Grid', type: 'checkboxGrid', icon: Icon9 },
    // { label: 'Date', type: 'date', icon: Icon10 },
    // { label: 'Time', type: 'time', icon: Icon11 },
  ];

  const handleOptionClick = (type) => {
    updateField({ ...field, type });
    if (type === 'multiplechoice' || type === 'checkbox' || type === 'fileupload' || type === 'dropdown') {
      updateOption({ fieldId: field.id });
      if (option?.id) {
        resetOptionItems({ optionId: option.id, type });
      }
      if (type === 'fileupload') {
        updateOptionItem({
          optionId: option?.id,
          updateitem: {
            type: 'fileupload',
            allowFileTypes: false,
            maxFiles: 1,
            maxSize: '1 MB',
          },
        });
      }
    }
    setIsOpen(false);
    setSelectedOption(optionsArray.find((opt) => opt.type === type).label);

  };

  let selectedOptionObject = optionsArray.find((opt) => opt.label === selectedOption);


  useEffect(() => {
    if (!selectedOption && location.pathname === "/edit-airdrop") {
      let fieldType = content?.fields && content?.fields[0]?.type;

      let selectedOptionObject = optionsArray.find((opt) => opt.type === fieldType);

      if (selectedOptionObject) {
        setSelectedOption(selectedOptionObject.label)
      }

    }
  }, [selectedOption, content])
  return (
    <div>
      <div className="airdrops_tabs_bg mt-4" key={index}>
        <div className="row">
          <div className="col-9">
            <div className="">
              <label htmlFor="question"></label>
              <input
                type="text"
                id="question"
                placeholder="Untitled Question"
                name="question"
                className="form_input_field_question"
                value={field?.question}
                onChange={handleQuestionChange}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="d-flex justify-content-end">
              {/* <div>
                <img src={gallerynavigationImage} alt="galleryImage" className="me-4" width="32px"></img>
              </div> */}
              <div ref={selectRef} className={`custom-select-wrapper-form ${isOpen ? 'open' : ''}`}>
                <div
                  className={`custom-select-header-form ${selectedOptionObject ? selectedOptionObject.label.toLowerCase() : ''
                    }`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {selectedOptionObject ? (
                    <>
                      <img src={selectedOptionObject.icon} alt="" className="me-2" width="25px" />
                      {selectedOptionObject.label}
                    </>
                  ) : (
                    'Select an option'
                  )}
                  <img src={arrowdown} alt="arrowImage" className="ms-4" width="12px"></img>
                </div>
                {isOpen && (
                  <ul className="custom-select-options-form">
                    {optionsArray.map((option) => (
                      <li key={option.label} className="li_cursor mb-3" onClick={() => handleOptionClick(option.type)}>
                        <img src={option.icon} alt="" width="25px" className="me-2" />
                        {option.label}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <FormBuilders field={field} onInputChange={() => handleFormInputChange()} />
        </div>
        <div className="d-flex justify-content-end pt-3">
          {/* {selectedOption === 'Multiple Choice' ? (
            ''
          ) : ( */}
          <div className="mt-2 me-4">
            <span className="required_text pe-3">
              {selectedOption === 'Multiple choice grid' ? 'Require a response in each row' : 'Required'}
            </span>
            <input
              className="toggle-checkbox"
              type="checkbox"
              disabled={field ? false : true}
              id={`required-${index}`}
              checked={field?.isRequired}
              onChange={handleCheckboxChange}
            />
            <label className="required-label" htmlFor={`required-${index}`}></label>
          </div>
          {/* )} */}
          <div className="line_vr_bar me-4 mt-1"></div>
          <div>
            {/* <img src={copyIconImage} alt="" className="me-4 icon_cursor" width="25px"></img> */}
            <img src={deleteIconImage} alt="" className="icon_cursor" width="25px" onClick={handleDeleteClick}></img>
          </div>
        </div>
      </div>
    </div>
  );
};
DuplicateForm.propTypes = {
  index: PropTypes.number.isRequired,
  isRequired: PropTypes.bool,
  onCheckboxChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};
export default DuplicateForm;
