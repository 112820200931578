function processFiles(fileList) {
  const files = [];
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList[i];
    // Process each file as needed, e.g., upload to a server or store in an array
    files.push(file);
  }
  return files;
}

function processFormData(formData) {
  const processedData = new FormData();

  const processValue = (key, value) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        processValue(`${key}[${index}]`, item);
      });
    } else if (typeof value === 'object' && value !== null) {
      if ('contents' in value && Array.isArray(value.contents)) {
        // Handle special case for 'sections' with 'contents' array
        processValue(key, value.contents);
      } else {
        // For other nested objects, continue recursively
        for (const subKey in value) {
          if (Object.prototype.hasOwnProperty.call(value, subKey)) {
            processValue(`${key}.${subKey}`, value[subKey]);
          }
        }
      }
    } else {
      processedData.append(key, value);
    }
  };

  for (const key in formData) {
    if (Object.prototype.hasOwnProperty.call(formData, key)) {
      const value = formData[key];
      processValue(key, value);
    }
  }

  return processedData;
}

export default processFormData;
