import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Shortquestion = ({ onInputChange }) => {
  const [formData, setFormData] = useState({
    description: '',
    shortAnswer: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Pass the updated formData to the parent component
    onInputChange({ ...formData, [name]: value });
  };

  return (
    <div>
      <div className="mt-2">
        <label htmlFor="description"></label>
        <input
          type="text"
          id="description"
          placeholder="Description"
          name="description"
          className="form_input_field"
          value={formData.description}
          onChange={handleInputChange}
          disabled
        />
      </div>
      <div className="mt-3">
        <label htmlFor="shortanswer"></label>
        <input
          type="text"
          id="shortanswer"
          placeholder="Short Answer"
          name="shortAnswer"
          className="form_input_field"
          value={formData.shortAnswer}
          onChange={handleInputChange}
          disabled
        />
      </div>
    </div>
    
  );
};

Shortquestion.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default Shortquestion;
