import React, { useEffect, useState } from 'react';
import './Airdropdatatable.css';
import { useGetAirdropsQuery } from '../../config/Apibase';
import Airdroptopcard from './Airdroptopcard';
import { AirdropImportProvider } from '../../hooks/airdropImportedContext';

const Airdropdatatable = () => {
  // Airdrops Card Data

  const [previousResponse, setPreviousResponse] = useState(null);
  const { data: cardData, refetch: cardRefetch, isLoading: isLoadingCard } = useGetAirdropsQuery({ isClaim: true });
  useEffect(() => {
    if (cardData) {
      setPreviousResponse([...cardData.airdrops]);
    }
  }, [cardData]);
  console.log('🚀 ~ Airdropdatatable ~ cardData:', cardData);
  return (
    <div>
      <div className="data_bg_color">
        <div className="container">
          <div className="row">
            {previousResponse?.map((item, index) => (
              <Airdroptopcard item={item} key={index} />
            ))}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Airdropdatatable;
