import { endpoints } from './endpoint';

const config = {
  api: {
    baseUrl:
      process.env.REACT_APP_NODE_ENV_LOCAL == 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL,
    endpoints,
  },
  chain: {
    polygonMainnet: process.env.REACT_APP_POLYGON_CHAIN_ID,
    polygonMumbai: process.env.REACT_APP_POLYGON_MUMBAI_CHAIN_ID,
  },
  airDropAddress: {
    polygonMainnet: process.env.REACT_APP_POLYGON_ADDRESS,
    polygonMumbai: process.env.REACT_APP_POLYGON_MUMBAI_ADDRESS,
  },
};

export default config;
