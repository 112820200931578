import React, { useState, useEffect } from 'react';
import '../CreateAirdropForm.css';
import crossiconimg from '../../../assets/images/crossIconImage.png';
import PropTypes from 'prop-types';
import { useFormContext } from '../../../hooks/contextHook';
const MultipleChoice = ({ onInputChange, field }) => {
  const [options, setOptions] = useState(['Option 1']);
  const { updateOptionItem, deleteOptionItem } = useFormContext();
  const option = field?.options[0];
  const fieldId = field?.id;
  const [optionsModified, setOptionsModified] = useState(false);

  const addOption = () => {
    updateOptionItem({ optionId: option?.id, updateitem: { name: 'Multiple Choice', type: 'multiplechoice' } });
  };

  const handleInputChange = (item, newName) => {
    updateOptionItem({ optionId: option?.id, updateitem: { ...item, name: newName } });
  };
  const removeCheckbox = (item) => {
    deleteOptionItem({ optionItemId: item?.id });
  };
  // useEffect will run only when optionsModified is true
  useEffect(() => {
    if (optionsModified) {
      onInputChange({
        options: options,
        numOptions: options.length,
      });
      setOptionsModified(false); // Reset the flag after updating parent
    }
  }, [optionsModified, options, onInputChange]);

  return (
    <div>
      {option?.optionItems?.map((option, index) => (
        <div key={index} className="mb-2 mt-2 d-flex align-items-center ps-0 w-100">
          <label htmlFor={`option${index + 1}`} className="options_label_circle me-3"></label>
          <input
            type="text"
            id={`option${index + 1}`}
            name={`option${index + 1}`}
            value={option?.name}
            onChange={(e) => handleInputChange(option, e.target.value)}
            className="options_input_field"
          />
          <img
            src={crossiconimg}
            alt="Cross"
            width="15px"
            className="ms-4 cross_icon_cursor"
            onClick={() => removeCheckbox(option)}
          />
        </div>
      ))}
      <div className="mb-2 mt-2 d-flex align-items-center">
        <span className="option_label_text pt-2">
          <span className="text_blue_color" onClick={addOption}>
            Add &quot;another&quot; Option
          </span>
        </span>
      </div>
      <div className="border-bottom-form-hr mt-3"></div>
    </div>
  );
};
MultipleChoice.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default MultipleChoice;
