import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import { useSignInMutation } from '../../config/Apibase';
import blacklogo from '../../assets/images/coloredlogo.png';
import { FaPlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUserWallet,
  logout,
  removeUserWallet,
  selectIsUserWalletConnected,
  selectToken,
} from '../../redux/slices/userSlice';

const NewNav = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const connected = useSelector(selectIsUserWalletConnected);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  // const res = signIn();
  // useEffect(() => {
  //   checkMetaMaskConnection();
  // }, []);
  const location = useLocation();
  const pathname = location?.pathname;

  const checkMetaMaskConnection = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        if (accounts.length > 0) {
          dispatch(addUserWallet(accounts[0]));
        }
      }
    } catch (error) {
      console.error('Error checking MetaMask connection:', error);
    }
  };

  const OnHandleSignIn = async () => {
    try {
      if (window.ethereum) {
        const connect = await window.ethereum.request({ method: 'eth_requestAccounts' });
        if (connect?.length) {
          dispatch(addUserWallet(connect[0]));
        }
      } else {
        toast.error('MetaMask is not installed');
      }
    } catch (error) {
      toast.error(`Error signing message: ${error}`);
    }
  };

  const OnHandleSignOut = async () => {
    try {
      if (window.ethereum) {
        const disconnect = await window.ethereum.request({
          method: 'wallet_revokePermissions',
          params: [
            {
              eth_accounts: {},
            },
          ],
        });
        dispatch(logout());
      } else {
        toast.error('MetaMask is not installed');
      }
    } catch (error) {
      toast.error(`Error disconnecting MetaMask: ${error}`);
    }
  };

  const renderLogo = () => {
    if (pathname === '/' || pathname === '/users-dashboard') {
      return <img src="https://ideaology.io/imgs/logo/logo-white.svg" height="41px" alt="Ideaology Logo" />;
    } else {
      return <img src={blacklogo} height="50px" alt="Black Logo" />;
    }
  };

  // Render dropdown menu
  const renderDropdownMenu = () => {
    return (
      <ul className="dropdown-menu py-lg-4 py-md-3 py-3 px-1">
        <li>
          <a
            className="dropdown-item pb-3"
            href="https://play.google.com/store/apps/details?id=com.mms.savethepuppies.rescuedog.puppygames"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://ideaology.io/games/dog.png" className="rounded-2" alt="Save the Doge" />
            <span className="ps-3">Save the Doge</span>
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            href="https://play.google.com/store/apps/details?id=com.IdeaologyStudio.RealPoolBallBilliardGames"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://ideaology.io/games/pool.png" className="rounded-2" alt="Pool Master Billiard" />
            <span className="ps-3">Pool Master Billiard</span>
          </a>
        </li>
      </ul>
    );
  };

  const renderConnect = () => {
    return (
      <Link
        to="/"
        className="apply_pool_btn text-decoration-none"
        onClick={connected ? OnHandleSignOut : OnHandleSignIn}
      >
        {connected ? 'Disconnect' : 'Connect'}
      </Link>
    );
  };

  // Render navigation links
  const renderNavigationLinks = () => {
    if (pathname === '/' || pathname === '/users-dashboard') {
      return (
        <>
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0 position-relative">
            {/* Highlight sections */}
            <div className="highLightLaunchNew d-none d-lg-block">
              <span className="highLightTextNew">New</span>
            </div>
            <div className="highLightLaunch d-none d-lg-block">
              <span className="highLightText">New Launched</span>
            </div>
            {/* Dropdown menu */}
            <li className="nav-item dropdown d-none" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <Link className="nav-link dropdown-toggle" to="/" role="button" aria-expanded="false">
                Games
              </Link>
              {renderDropdownMenu()}
            </li>
            {/* Links */}
            <li className="nav-item">
              <Link className="nav-link" to="https://workaspro.com/" target="_blank" rel="noreferrer">
                WorkAsPro
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="https://www.manialands.com/" target="_blank" rel="noreferrer">
                Manialands
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="https://ideaology-1.gitbook.io/ideaology-1/"
                target="_blank"
                rel="noreferrer"
              >
                Docs
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="https://ideaology.io/voting" target="_blank" rel="noreferrer">
                Voting
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="https://ideaology.io/voting" target="_blank" rel="noreferrer">
                Get IDEA
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="https://ideaology.io/swap" target="_blank" rel="noreferrer">
                Swap IDEA
              </Link>
            </li>
          </ul>
          {renderConnect()}
        </>
      );
    } else {
      return null;
    }
  };

  // Render authentication links
  const renderAuthLinks = () => {
    if (token && window.location.pathname !== '/' && !pathname.includes('users-dashboard')) {
      return (
        <>
          <Link to="/all-airdrops" className="apply_for_pool_btn text-decoration-none ms-auto me-3">
            All Airdrops
          </Link>
          {!pathname.includes('create-airdrop') && (
            <Link to="/create-airdrop" className="apply_for_pool_btn text-decoration-none">
              <FaPlus className="me-2" /> Create Airdrop
            </Link>
          )}
          <span
            className="apply_for_pool_btn text-decoration-none ms-3"
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(logout(true))}
          >
            Logout
          </span>
        </>
      );
    }
  };

  return (
    <div className="sticky-top">
      <nav
        className={`navbar navbar-expand-lg ${pathname === '/' || pathname === '/users-dashboard' ? 'nav_bg' : 'nav_bg_white'}`}
      >
        <div className="container">
          <a className="navbar-brand" href="https://ideaology.io/" target="_blank" rel="noreferrer">
            {renderLogo()}
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {renderNavigationLinks()}
            {renderAuthLinks()}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NewNav;
