import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import { CSVLink } from 'react-csv';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Timer from '../AirdropCards/Timer';
import Datatable from './Datatable';
import { RxCross2 } from 'react-icons/rx';
import { AirDropContext } from '../../context/ethersContext';
import { useGenerateSignatureMutation } from '../../config/Apibase';
import { useAirdropImportContext } from '../../hooks/airdropImportedContext';
import handleCreateHanding from '../../helpers/dataFormHeaderBuilder';
import { ethers } from 'ethers';

function findValueByKey(array, key) {
  if (array === null) {
    return null; // or handle the case appropriately
  }

  for (let i = 0; i < array.length; i++) {
    const obj = array[i];
    if (obj !== null && typeof obj === 'object' && key in obj) {
      return obj[key];
    }
  }
  return null; // Key not found
}

function getKeysAndValues(airdrop, item) {
  const object = {};
  handleCreateHanding(airdrop)?.forEach((d, i) => {
    const sections = item?.sections?.[0];
    if (!sections) {
      return null; // Handle the case where sections is undefined
    }
    const value = findValueByKey(sections, d) || '';

    return (object[d] = value);
  });

  return object;
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Airdroptopcard = ({ item }) => {
  const [value, setValue] = React.useState(0);
  const [isExpired, setIsExpired] = useState(false);
  const [importedData, setImportedData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const { claimTxn, connectWallet } = useContext(AirDropContext);
  const [generateSignature, rest] = useGenerateSignatureMutation();

  const handleExpire = () => {
    setIsExpired(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Visible content

  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleToggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  useEffect(() => {
    // Your data transformation logic to convert 'data' to CSV format

    if (item && item.claims) {
      const approvedClaims = item.claims.some((claim) => claim.status === 'approved');
      setIsApproved(approvedClaims);
      var csvDataArray = item.claims.map((claim) => {
        const data = getKeysAndValues(item, claim);
        return { ...claim, ...data };
      });
      if (importedData) csvDataArray = csvDataArray.concat(importedData);
      setCsvData(csvDataArray);
    }
  }, [item, importedData]);

  const getStatusValue = () => {
    if (value === 1) return 'approved';
    else if (value === 2) return 'rejected';
    else if (value === 3) return 'sent';
    else return 'pending';
  };

  const expirationDateTime = new Date(item?.expirationDate);

  const [selectedFile, setSelectedFile] = useState(null);
  const generateSignatureHandler = async () => {
    if (csvData?.length > 0 && amount > 0) {
      let airdropAmount = Math.floor(amount / csvData?.length);
      let airdropAmountInWei = ethers.utils.parseEther(airdropAmount.toString());
      const recipients = [],
        amounts = [];
      csvData.map((item, i) => {
        if (item.status == 'approved') {
          recipients.push(item.walletAddress);
          amounts.push(airdropAmountInWei.toString());
        }
      });
      const res = await generateSignature({ recipients, amounts });
      await connectWallet();
      await claimTxn(recipients, amounts, res.data.timeStamp, res.data.signature, item._id);
      setAmount(0);
      setImportedData(null);
      setSelectedFile(null);
    }
  };

  const handleRemoveFile = () => {
    setImportedData(null);
    setSelectedFile(null);
  };

  return (
    <div>
      <div className="col-12">
        <div className="card airdrop_home_card mb-lg-5 mb-md-4 mb-3 mt-lg-2 mt-md-2 mt-3">
          <div className="row">
            <div className="col-lg-2">
              <div>
                <p className="offer_created_text mb-0">Offer Created</p>
                <p className="offer_created_date">
                  {new Date(item?.createdAt).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}
                </p>
                <p className="offer_created_text mb-0">Expiry</p>
                <p className="offer_created_remaining_days mb-0">
                  <Timer targetDate={expirationDateTime} onExpires={handleExpire} />{' '}
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className={` h-100 align-items-start`}>
                <div className="text-center">
                  <h5 className="airdrops_home_card_text_h mb-3 pe-lg-5">{item?.sections?.[0]?.title}</h5>
                </div>
                <div className={` `}>
                  <p
                    className="airdrops_home_card_text_p mb-0"
                    dangerouslySetInnerHTML={{
                      __html: item?.sections?.[0]?.description.slice(0, 950),
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className={`d-flex justify-content-lg-end justify-content-center align-item-start`}>
                <button className={`claim_now_btn mt-lg-0 mt-md-3 mt-3`} onClick={handleToggleContent}>
                  {isContentVisible ? 'View Less' : 'View More'}
                </button>
              </div>
            </div>
          </div>
          {isContentVisible && (
            <div className="row">
              <div className="col-12 pt-3 ">
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Pending" {...a11yProps(0)} className="tabs_buttons me-2" />
                      <Tab label="Accepted" {...a11yProps(1)} className="tabs_buttons me-2" />
                      <Tab label="Rejected" {...a11yProps(2)} className="tabs_buttons me-2" />
                      <Tab label="Sent" {...a11yProps(3)} className="tabs_buttons" />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    {/* <div className="row">
                      <div className="col-12">
                        <Datatable data={item} status={"pending"} />
                      </div>
                    </div> */}
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    {/* <Datatable data={item} status={"accepted"} /> */}
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={2}>
                    {/* <Datatable data={item} status={"rejected"} /> */}
                  </CustomTabPanel>

                  <div className="row">
                    <div className="col-12">
                      <Datatable data={item} status={getStatusValue()} />
                    </div>
                  </div>
                </Box>
              </div>
              <div className="col-12 pt-3">
                <div className="text-end">
                  {/* <button className="table_export_button me-2" onClick={handleImportClick}>
                    Import
                  </button>
                  <input
                    id={'csv-reader-input-' + item?._id}
                    type="file"
                    accept=".csv"
                    onChange={handleFileInputChange}
                    style={{ display: 'none' }}
                  /> */}
                  {/* <button className="table_export_button">Export</button> */}
                  <CSVLink data={csvData} filename={'airdrops_data.csv'} className="apply_pool_btn_csv">
                    Export CSV
                  </CSVLink>
                  <div>
                    <div>
                      {selectedFile && (
                        <div className="d-flex align-items-center h-100 justify-content-end">
                          <p className="mb-0 py-2 airdrops_home_card_text_p pe-2">
                            <b>Selected file:</b> {selectedFile.name}
                          </p>{' '}
                          <span>
                            <RxCross2 style={{ cursor: 'pointer' }} onClick={handleRemoveFile} />
                          </span>
                        </div>
                      )}
                    </div>
                    {/* airdrop expire or 1  bnda approve ho .  */}
                    {/* ik airdrop ik hi dafa */}
                    {isExpired &&
                      (item.status == 'completed' ? (
                        <label htmlFor="value_to_send" className="table_export_button mb-2  mt-2">
                          Completed
                        </label>
                      ) : (
                        isApproved && (
                          <>
                            <label htmlFor="value_to_send" className="airdrops_home_card_text_p mb-2">
                              Enter Value:{' '}
                            </label>
                            <input
                              type="number"
                              value={amount}
                              onChange={(e) => {
                                setAmount(parseInt(e.currentTarget.value));
                              }}
                              id="value_to_send"
                              name="value_to_send"
                              placeholder="Enter Value"
                              className="claimdrop_top_input"
                            />
                            <div>
                              <button className="table_export_button mt-2" onClick={generateSignatureHandler}>
                                Send
                              </button>
                            </div>
                          </>
                        )
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Airdroptopcard;
