import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Paragraph = ({ onInputChange }) => {
  const [paragraphData, setParagraphData] = useState({
    description: '',
    longAnswer: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setParagraphData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Pass the updated paragraphData to the parent component
    onInputChange({ ...paragraphData, [name]: value });
  };

  return (
    <div>
      <div className="mt-2">
        <label htmlFor="description"></label>
        <input
          type="text"
          id="description"
          placeholder="Description"
          name="description"
          className="form_input_field"
          value={paragraphData.description}
          onChange={handleInputChange}
          disabled
        />
      </div>
      <div className="mt-3">
        <label htmlFor="longanswer"></label>
        <input
          type="text"
          id="longanswer"
          placeholder="Long Answer text"
          name="longAnswer"
          className="form_input_field"
          value={paragraphData.longAnswer}
          onChange={handleInputChange}
          disabled
        />
      </div>
    </div>
  );
};
Paragraph.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};
export default Paragraph;
