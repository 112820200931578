import { Axios } from 'axios';

export const getGeoLocation = async () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        const REACT_APP_GEOCODE_API_KEY = 'bdc_3e1a27cac7ca40cbab9cb1ed3e133400';
        fetch(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en&key=${REACT_APP_GEOCODE_API_KEY}`,
        )
          .then((response) => response.json())
          .then((data) => {
            const userCountry = data.countryName;
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            resolve({ country: userCountry, timezone });
          });
      },
      () => {
        Axios.get('https://1.1.1.1/cdn-cgi/trace')
          .then((resp) => {
            let loc = resp?.data?.substring(resp?.data?.indexOf('loc=') + 4);
            loc = loc.substring(0, loc.indexOf('\n')).toLowerCase();
            const name = CountryCodes.find((o) => o.alpha2 == loc);
            const timezone = (new Date().getTimezoneOffset() / 60) * -1;
            resolve({ country: name.name, timezone });
          })
          .catch(() => resolve(''));
      },
    );
  });
};
