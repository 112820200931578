import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useForm from './useForm';
import useSections from './useSections';
import useContents from './useContents';
import useFields from './useFields';
import useOptions from './useOptions';
import useFileOptions from './useFileOptions';
import useOptionItem from './useOptionItem';

// Step 1: Create a context
const MyContext = createContext();

const initialForm = {
  id: uuidv4(),
  name: '',
};
const initialSection = {
  id: uuidv4(),
  title: '',
  formId: initialForm.id,
  description: '',
};
const initialContent = {
  id: uuidv4(),
  sectionId: initialSection.id,
};
export const initialField = {
  id: uuidv4(),
  contentId: initialContent.id,
  type: 'text',
  question: '',
  isRequired: false,
};
export const initialOptions = {
  id: uuidv4(),
  fieldId: initialField.id,
};
export const initialFileOptions = {
  id: uuidv4(),
  fieldId: initialField.id,
};
export const initialOptionsItem = {};
/// allowFileTypes: false,
// maxFiles: '1',
// maxSize: '1 MB',

// Step 2: Create a provider component
const FormProvider = ({ children }) => {
  const [form, setForm] = useState(initialForm);
  const [sections, setSections] = useState([initialSection]);
  const [contents, setContents] = useState([initialContent]);
  const [fields, setFields] = useState([initialField]);
  const [options, setOptions] = useState([initialOptions]);
  const [fileOptions, setFileOptions] = useState([initialFileOptions]);
  const [optionItems, setOptionItem] = useState([]);

  const { updateForm, getFormatedForm } = useForm({
    setForm,
    sections,
    contents,
    fields,
    options,
    form,
    fileOptions,
    optionItems,
  });
  const { updateSection } = useSections({ sections, setSections });
  const { updateContent, deleteConent } = useContents({
    contents,
    setContents,
    fields,
    options,
    setOptions,
    setFields,
  });
  const { updateField } = useFields({ setFields, fields });
  const { updateOption } = useOptions({ options, setOptions, fields });
  const { updateFileOption } = useFileOptions({ fileOptions, setFileOptions, fields });
  const { updateOptionItem, resetOptionItems, deleteOptionItem } = useOptionItem({
    optionItems,
    setOptionItem,
    options,
  });

  const updateFormForEdit = (airdrop) => {

    setForm(airdrop?.form);

    //update sections 
    const sections = airdrop?.sections?.map(section => {

      return {
        id: section.id,
        formId: section.formId,
        title: section.title,
        description: section.description
      }
    })
    setSections(sections)

    let contents_1 = [];
    let fields_1 = [];
    let options_1 = [];
    let optionItems_1 = [];

    airdrop?.sections?.forEach(section => {

      let sectionContents = section.contents.map(content => {

        return {
          id: content.id,
          sectionId: content.sectionId
        }
      })

      contents_1.push(...sectionContents)


      section.contents.forEach(content => {

        const contentFields = content?.fields.map(field => {

          return {
            id: field.id,
            contentId: field.contentId,
            type: field.type,
            question: field.question,
            isRequired: field.isRequired
          }
        })
        fields_1.push(...contentFields);

        content?.fields?.forEach(field => {

          const fieldOptions = field.options?.map(option => {

            return {
              id: option.id,
              fieldId: option.fieldId
            }
          })

          options_1.push(...fieldOptions)


          field?.options?.forEach(option => {

            const optionItemsCloned = option?.optionItems?.map(optionItem => optionItem)
            optionItems_1.push(...optionItemsCloned)
          })
        })


      })

    })


    setContents(contents_1);
    setFields(fields_1);
    setOptions(options_1);
    setOptionItem(optionItems_1)


  }

  // console.log("@765 Form => ", getFormatedForm())

  const contextValues = {
    getFormatedForm,
    updateForm,
    resetOptionItems,
    updateSection,
    updateContent,
    deleteConent,
    updateField,
    updateOption,
    updateOptionItem,
    deleteOptionItem,
    updateFileOption,
    updateFormForEdit
  };

  return <MyContext.Provider value={contextValues}>{children}</MyContext.Provider>;
};

// Step 3: Create a custom hook to use the context
const useFormContext = () => {
  const context = useContext(MyContext);

  if (!context) {
    throw new Error('useMyContext must be used within a MyProvider');
  }

  return context;
};

export { FormProvider, useFormContext };
