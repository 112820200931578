import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const useSections = ({ sections, setSections }) => {
  // Utility function to add or edit a section
  const updateSection = ({ sectionId, title, formId, description }) => {
    // If the section ID is provided, check if the section exists
    const existingSection = sections.find((section) => section.id === sectionId);
    if (existingSection) {
      // Editing an existing section
      const updatedSections = sections.map((section) =>
        section.id === sectionId ? { ...section, title: title, formId: formId, description: description } : section,
      );
      setSections(updatedSections);
    } else {
      // Adding a new section
      setSections((prevSections) => [
        ...prevSections,
        { id: uuidv4(), title: title, formId: formId, description: description },
      ]);
    }
  };

  return { updateSection };
};

export default useSections;
