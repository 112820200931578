import React, { useState, useEffect } from 'react';
import '../CreateAirdropForm.css';
import driveimage from '../../../assets/images/driveImg.png';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormContext } from '../../../hooks/contextHook';
const FileUpload = ({ onInputChange, field }) => {
  const { updateOptionItem } = useFormContext();
  const option = field?.options[0];
  const optionItem = field?.options[0]?.optionItems[0];
  const fieldId = field?.id;
  const [fileUploadData, setFileUploadData] = useState({
    allowFileTypes: false,
    maxNumFiles: 1,
    maxFileSize: '1 MB',
  });

  const [hasInteracted, setHasInteracted] = useState(false);

  const handleAllowFileTypesChange = (e) => {
    const allowFileTypes = e.target.checked;
    console.log('@123 option id 1 => ', option.id);
    updateOptionItem({ optionId: option.id, updateitem: { ...optionItem, allowFileTypes } });
  };

  const handleMaxNumFilesChange = (e) => {
    const maxFiles = e.target.value;
    console.log('@123 option id 2 => ', option.id);
    updateOptionItem({ optionId: option.id, updateitem: { ...optionItem, maxFiles } });
  };

  const handleMaxFileSizeChange = (e) => {
    const maxSize = e.target.value;
    console.log('@123 option id 3 => ', option.id);
    updateOptionItem({ optionId: option.id, updateitem: { ...optionItem, maxSize } });
  };

  useEffect(() => {
    if (hasInteracted) {
      onInputChange(fileUploadData);
      setHasInteracted(false); // Reset to false after processing
    }
  }, [fileUploadData, hasInteracted, onInputChange]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-5 col-12">
          <div className="d-flex justify-content-between pt-3">
            <div>
              <p className="allow_file_type_p">Allow only file types</p>
            </div>
            <div>
              <div className="mt-1">
                <span className="required_text pe-3"></span>
                <input
                  className="toggle-checkbox"
                  type="checkbox"
                  id="filetype"
                  checked={optionItem?.allowFileTypes}
                  onChange={handleAllowFileTypesChange}
                />
                <label className="required-label" htmlFor="filetype"></label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <p className="allow_file_type_p ">Maximum number of files</p>
            </div>
            <div>
              <select
                name="selectfiles"
                id="selectfiles"
                className="select_files_type"
                value={optionItem?.maxFiles}
                onChange={handleMaxNumFilesChange}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between pt-2">
            <div>
              <p className="allow_file_type_p">Maximum file size</p>
            </div>
            <div>
              <select
                name="selectfiles"
                id="selectfiles"
                className="maximum_file_size"
                value={optionItem?.maxSize}
                onChange={handleMaxFileSizeChange}
              >
                {['1 MB', '2 MB', '3 MB', '4 MB', '5 MB', '6 MB', '7 MB', '8 MB', '9 MB', '10 MB'].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-between pt-2">
            <div>
              <p className="form_accept_p pt-3 mb-0">This form can accept up to 10 MB of files.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-12">
          <Link className="text-decoration-none" to="/">
            <div className="d-none justify-content-end align-items-end h-100 pb-5">
              <div>
                <p className="view_folder_p mb-0 pe-2 pt-1">View Folder</p>
              </div>
              <div>
                <img src={driveimage} alt="driveImage" width="25px"></img>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="border-bottom-form-hr mt-3"></div>
    </div>
  );
};

FileUpload.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default FileUpload;
