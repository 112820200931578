import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
const Linearscale = ({ onInputChange }) => {
  const [linearScaleData, setLinearScaleData] = useState({
    startLabel: '',
    endLabel: '',
    labels: ['', ''],
  });

  const [hasInteracted, setHasInteracted] = useState(false);

  const handleStartLabelChange = (e) => {
    setLinearScaleData((prevData) => ({
      ...prevData,
      startLabel: e.target.value,
    }));
    setHasInteracted(true);
  };

  const handleEndLabelChange = (e) => {
    setLinearScaleData((prevData) => ({
      ...prevData,
      endLabel: e.target.value,
    }));
    setHasInteracted(true);
  };

  const handleLabelInputChange = (index, value) => {
    const updatedLabels = [...linearScaleData.labels];
    updatedLabels[index] = value;
    setLinearScaleData((prevData) => ({
      ...prevData,
      labels: updatedLabels,
    }));
    setHasInteracted(true);
  };

  useEffect(() => {
    if (hasInteracted) {
      onInputChange(linearScaleData);
      setHasInteracted(false); // Reset to false after processing
    }
  }, [linearScaleData, hasInteracted, onInputChange]);

  return (
    <div>
      <div className="col-3">
        <div className="d-flex justify-content-between pt-2">
          <div>
            <select name="selectlabel" id="selectlabel" className="select_files_type" onChange={handleStartLabelChange}>
              <option value="0">0</option>
              <option value="1">1</option>
            </select>
          </div>
          <div>
            <p className="mb-0">to</p>
          </div>
          <div>
            <select name="selectlabel2" id="selectlabel2" className="select_files_type" onChange={handleEndLabelChange}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        {linearScaleData.labels.map((label, index) => (
          <div key={index} className="mb-2 mt-2 d-flex align-items-center ps-0 w-100">
            <label htmlFor={`linearInput${index + 1}`} className="">
              <span className="me-3 dropdown_count_span">{index + 1}. </span>
            </label>
            <input
              type="text"
              id={`linearInput${index + 1}`}
              name={`linearInput${index + 1}`}
              value={label}
              className="options_input_field"
              onChange={(e) => handleLabelInputChange(index, e.target.value)}
              placeholder={`Optional (Label ${index + 1})`}
            />
          </div>
        ))}
      </div>
      <div className="border-bottom-form-hr mt-3"></div>
    </div>
  );
};
Linearscale.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};
export default Linearscale;
