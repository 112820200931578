import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import './variables/variables.css';
import Airdropdata from './pages/Airdropsdata/Airdropdata';
import CreateAirdrop from './pages/Createairdrop/CreateAirdrop';
import SignIn from './pages/Signin/Signin';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import Allairdrops from './components/AllAirdrops/Allairdrops';
import Usersdashboard from './components/AirdropCards/Usersdashboard';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import AdminPrivateRoute from './AdminPrivateRoute';
import PrivateRoute from './PrivateRoute';
function App() {
  return (
    <div>
      <ToastContainer position="top-left" />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/airdrop" element={<AdminPrivateRoute element={<Airdropdata />} />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/users-dashboard" element={<PrivateRoute element={<Usersdashboard />} />} />
          <Route path="/create-airdrop" element={<AdminPrivateRoute element={<CreateAirdrop />} />} />
          <Route path="/edit-airdrop" element={<AdminPrivateRoute element={<CreateAirdrop />} />} />
          <Route path="/all-airdrops" element={<AdminPrivateRoute element={<Allairdrops />} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
