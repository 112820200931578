import React, { useState, useRef } from 'react';
import { useCustomFormikContext } from '../../hooks/contextFormikHook';

function FileUpload({ contentCount, data }) {
  const fileInputRef = useRef(null);
  const { formik } = useCustomFormikContext();
  const [selectedFileName, setSelectedFileName] = useState('');
  const handleFileChange = (event) => {
    const selectedFile = event.currentTarget.files[0];
    event.preventDefault();
    formik.setFieldValue('image' + '-c-' + contentCount, selectedFile);
    setSelectedFileName(selectedFile ? selectedFile?.name : '');
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  return (
    <div>
      <label htmlFor="shortQuestion" className='airdrops_home_card_text_p mb-1'><span className='fw-bold pe-1'>{data?.question}</span>{data?.isRequired && <span style={{ color: 'red' }}>*</span>}
      </label>
      <div className="d-flex justify-content-center">
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          required={data?.isRequired}
          onChange={handleFileChange}
          name={'image' + '-c-' + contentCount}
        />
        <button onClick={handleButtonClick} className="select_img_input w-100 mb-3">
          Choose File To Upload {selectedFileName ? `(${selectedFileName})` : ""}
        </button>
      </div>
    </div>
  );
}

export default FileUpload;
