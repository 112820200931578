import React from 'react';
import './AirdropDetails.css';

const AirdropDetails = ({ dangerouslySetInnerHTML }) => {
  return (
    <>
      <div className="card airdrop_description_card">
        <div className="project_description_card">
          <h3 className="description_heading text-center">Project Description</h3>
          <div className="description">
            <p className="description_text" dangerouslySetInnerHTML={dangerouslySetInnerHTML}></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AirdropDetails;
