import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Allairdropsrow from './Allairdropsrow';
import { useGetAirdropsQuery } from '../../config/Apibase';
import { formatDate } from '../../helpers';

const Allairdrops = () => {
  const { data, refetch, isLoading } = useGetAirdropsQuery({ page: 1, limit: 20 });

  const transformData =
    data?.airdrops?.map((a) => ({
      id: a._id,
      Airdropname: a.sections?.[0]?.title || '',
      date: a.createdAt ? formatDate(a.createdAt) : 'N/A',
      expirationDateTime: a.expirationDate || 'N/A',
    })) || [];

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-12 spacing_bottom">
            <div className="airdrop_table_shadow table_overflow_x_scroll my-lg-5 my-md-4 my-3">
              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 className="airdrops_data_h5 pt-2">All Airdrops</h5>
                  </div>
                </div>
              </div>
              <div>
                <table className="table">
                  <thead>
                    <tr className="">
                      <th scope="col" className="columns_top_bottom_space">
                        <span className="airdrop_table_h">Id</span>
                      </th>
                      <th scope="col" className="columns_top_bottom_space">
                        <span className="airdrop_table_h">AirDrop Name</span>
                      </th>
                      <th scope="col" className="columns_top_bottom_space">
                        <span className="airdrop_table_h">Date Created</span>
                      </th>
                      <th scope="col" className="columns_top_bottom_space">
                        <span className="airdrop_table_h">Expiry</span>
                      </th>
                      <th scope="col" className="columns_top_bottom_space">
                        <span className="airdrop_table_h">Edit</span>
                      </th>
                      <th scope="col" className="columns_top_bottom_space">
                        <span className="airdrop_table_h">Delete</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transformData?.map((item, index) => (
                      <Allairdropsrow item={item} key={index} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Allairdrops;
