import React from "react";
import { Link } from "react-router-dom";
import { Container, makeStyles } from "@material-ui/core";
import certikSVG from "../../assets/images/certik.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#1C2535",
    textAlign: "center",
    paddingBottom: "48px",
    fontFamily: "Rubik"
  },
  container: {},
  logo: {
    width: "102px",
    fontFamily: "Rubik"
  },
  listWrapper: {
    paddingTop: "64px",
    display: "flex",
    justifyContent: "space-around",
    color: "white",
    textAlign: "left",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      width: "312px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  menuTitle: {
    fontSize: 14,
    fontFamily: "Rubik"
  },
  menuList: {
    marginTop: 10,
    fontFamily: "Rubik"
  },
  menuItem: {
    padding: "2px 0px 2px 0px",
    fontFamily: "Rubik"
  },
  menuItemLink: {
    textDecoration: "none",
    color: "#cdd4df",
    fontSize: 14,
    fontFamily: "Rubik"
  },
  copyright: {
    color: "#cdd4df",
    fontSize: 12,
    lineHeight: "20px",
    fontFamily: "Rubik"
  },
  socialLogo: {
    height: 27,
    marginRight: 20,
    fontFamily: "Rubik"
  },
  full: {
    [theme.breakpoints.down("xs")]: {
      flexBasis: "100%",
    },
  },
  half: {
    paddingLeft: "5px",
    paddingRight: "5px",

    [theme.breakpoints.down("xs")]: {
      flexBasis: "50%",
      marginTop: "32px",
    },
  },
  auditWrapper: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "32px",
    },
  },
  certikWrapper: {
    color: "white",
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "Rubik"
  },
}));

const companyItems = [
  {
    link: `https://ido.ideaology.io/apply`,
    name: "Apply for IDO",
  },
  {
    link: "https://ideaology.io/blog",
    name: "Blogs",
  },
];

const helpItems = [
  {
    link: "https://ideaology.io/contact",
    name: "Contact us",
  },
  {
    link: "https://ideaology.io/terms",
    name: "Terms",
  },
  {
    link: "https://ideaology.io/staking-vesting",
    name: "Staking & Vesting",
  },
  {
    link: "http://localhost:3000/privacy-policy",
    name: "Privacy Policy",
  },
];

const developerItems = [
  {
    link: "https://ideaology-2.gitbook.io/what-is-ideaido/",
    name: "Documentation",
  },
];

const socialItems = [
  {
    link: "https://ideaologyio.medium.com/",
    name: "medium",
    imgSrc: "https://ideaology.io/imgs/logo/medium.svg",
  },
  {
    link: "https://t.me/ideaologyio",
    name: "telegram",
    imgSrc: "https://ideaology.io/imgs/logo/telegram.svg",
  },
  {
    link: "https://twitter.com/ideaologyio",
    name: "twitter",
    imgSrc: "https://ideaology.io/imgs/logo/twitter.svg",
  },
];

const Footer = (props) => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <div className={`${classes.root} ${props.className}`}>
      <Container className={classes.container} maxWidth="lg">
        <div className={classes.listWrapper}>
          <div className={classes.full}>
            <img
              alt="logo"
              className={classes.logo}
              src="	https://ideaology.io/imgs/logo/logo-white.png"
            />
            <div className={`${classes.menuList} ${classes.copyright}`}>
              Copyright @ {currentYear} Ideaology.io <br /> All Rights
              Reserved
            </div>
          </div>
          <div className={classes.half}>
            <span className={classes.menuTitle}>Company</span>
            <div className={classes.menuList}>
              {companyItems.map((element) => (
                <div className={classes.menuItem} key={element.name}>
                  <a className={classes.menuItemLink} href={element.link}>
                    {element.name}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.half}>
            <span className={classes.menuTitle}>Help</span>
            <div className={classes.menuList}>
              {helpItems.map((element) => (
                <div className={classes.menuItem} key={element.name}>
                  <Link className={classes.menuItemLink} to={element.link} target="_blank">
                    {element.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.half}>
            <span className={classes.menuTitle}>Developers</span>
            <div className={classes.menuList}>
              {developerItems.map((element) => (
                <div className={classes.menuItem} key={element.name}>
                  <a className={classes.menuItemLink} href={element.link}>
                    {element.name}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.half}>
            <span className={classes.menuTitle}>Social</span>
            <div className={classes.menuList}>
              {socialItems.map((element) => (
                <a href={element.link} key={element.name} target="_blank" rel="noreferrer">
                  <img
                    alt={element.name}
                    className={classes.socialLogo}
                    src={element.imgSrc}
                  />
                </a>
              ))}
            </div>
          </div>
          <div className={classes.auditWrapper}>
            <a
              className={classes.noDecoration}
              href="https://www.certik.org/projects/ideaology"
              rel="noreferrer"
              target="_blank"
              style={{textDecoration:"none"}}
            >
              <div className={classes.certikWrapper}>
                <span>Audited by:</span>
                <br />
                <img alt="certik" src={certikSVG} />
              </div>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
