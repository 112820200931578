import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const useFields = ({ setFields, fields }) => {
  // Utility function to add or edit a field
  const updateField = ({ contentId = '', type = 'text', question = '', isRequired = false }) => {
    if (!contentId) {
      return;
    }
    // If the field ID is provided, check if the field exists
    const existingField = fields.find((item) => item.contentId === contentId);
    if (existingField) {
      const fieldId = existingField.id;
      // Editing an existing field
      const updatedFields = fields.map((item) =>
        item.id === fieldId
          ? {
              ...item,
              contentId: contentId,
              type: type,
              question: question,
              isRequired: isRequired,
            }
          : item,
      );
      setFields(updatedFields);
    }
  };
  return { updateField };
};

export default useFields;
