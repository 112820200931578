import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { initialFileOptions } from './contextHook';

const useFileOptions = ({ fileOptions, setFileOptions, fields }) => {
  const updateFileOption = ({ fieldId, updatedOptionItem }) => {
    // Check if the field with the provided fieldId exists
    const existingField = fields.find((field) => field.id === fieldId);

    if (!existingField) {
      // If the field does not exist, do nothing or handle as needed
      return;
    }

    // Check if a file option with the given fieldId already exists
    const existingFileOptionIndex = fileOptions.findIndex((fileOption) => fileOption?.fieldId === fieldId);

    if (existingFileOptionIndex !== -1) {
      // Update the existing file option's optionItem
      const updatedFileOptions = [...fileOptions];
      updatedFileOptions[existingFileOptionIndex] = {
        ...fileOptions[existingFileOptionIndex],
        optionItem: { ...fileOptions[existingFileOptionIndex].optionItem, ...updatedOptionItem },
      };
      setFileOptions(updatedFileOptions);
    } else {
      // Create a new file option with the updated optionItem

      setFileOptions((prevFileOptions) => [
        ...prevFileOptions,
        {
          id: uuidv4(),
          fieldId: fieldId,
          optionItem: {
            ...initialFileOptions.optionItem,
            id: uuidv4(),
            ...updatedOptionItem,
          },
        },
      ]);
    }
  };

  return { updateFileOption };
};

export default useFileOptions;
