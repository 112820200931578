import React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { logout } from './redux/slices/userSlice';
import useToken from './pages/Airdropsdata/useToken';

const AdminPrivateRoute = ({ element: Component, ...rest }) => {
  const { token, isValid, isLoading } = useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (isLoading) {
    return <p>Checking token validity...</p>;
  }

  if (!isValid) {
    dispatch(logout());
    navigate('/signin');
    return null; // Return null to avoid rendering anything
  }

  return token ? Component : <Navigate to="/signin" />;
};

export default AdminPrivateRoute;
