import { useState } from 'react';
import { useFormik } from 'formik';
// Custom hook for handling forms with Formik
const useCustomFormik = (onSubmitCallback = null) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {},
    // validationSchema: Yup.object().shape(validationSchema),
    onSubmit: async (values, { resetForm }) => {
      try {
        // Call the provided onSubmitCallback with form values
        if (onSubmitCallback) {
          await onSubmitCallback(values);
          // Reset the form after successful submission
          resetForm();
        }
      } catch (error) {
        console.error('Form submission error:', error);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const resetClaimForm = () => {
    formik.resetForm()

  }


  return { formik, isSubmitting, resetClaimForm };
};

export default useCustomFormik;
