import React from 'react';
import {
  Shortquestion,
  Paragragh,
  Checkboxes,
  MultipleChoice,
  Multiplechoicegrid,
  Linearscale,
  FileUpload,
  Dropdown,
} from '../components/AirdropForm/QuestionComponents';
import PropTypes from 'prop-types';

function FormBuilders({ field, onInputChange }) {
  switch (field?.type) {
    case 'text':
      return <Shortquestion onInputChange={onInputChange} />;
    case 'paragraph':
      return <Paragragh onInputChange={onInputChange} />;
    case 'checkbox':
      return <Checkboxes onInputChange={onInputChange} field={field} />;
    case 'multiplechoice':
      return <MultipleChoice onInputChange={onInputChange} field={field} />;
    case 'multiplechoicegrid':
      return <Multiplechoicegrid onInputChange={onInputChange} />;
    case 'linearscale':
      return <Linearscale onInputChange={onInputChange} />;
    case 'fileupload':
      return <FileUpload onInputChange={onInputChange} field={field} />;
    case 'dropdown':
      return <Dropdown onInputChange={onInputChange} field={field} />;
    default:
      return null;
  }
}
FormBuilders.propTypes = {
  component: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  onInputChange: PropTypes.func.isRequired,
};
export default FormBuilders;
