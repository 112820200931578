import React, { useState, useEffect } from 'react';
import '../CreateAirdropForm.css';
import crossiconimg from '../../../assets/images/crossIconImage.png';
import PropTypes from 'prop-types';
import { useFormContext } from '../../../hooks/contextHook';
const Checkboxes = ({ onInputChange, field }) => {
  const [checkbox, setCheckbox] = useState(['Checkbox 1']);
  const { updateOptionItem, deleteOptionItem } = useFormContext();
  const option = field?.options[0];
  const [checkboxModified, setCheckboxModified] = useState(false);
  const fieldId = field?.id;
  const addCheckbox = () => {
    updateOptionItem({ optionId: option?.id, updateitem: { name: 'Checkbox', type: 'checkbox' } });
  };

  const handleInputChange = (item, newName) => {
    updateOptionItem({ optionId: option?.id, updateitem: { ...item, name: newName } });
  };

  const removeCheckbox = (item) => {
    deleteOptionItem({ optionItemId: item?.id });
  };

  useEffect(() => {
    if (checkboxModified) {
      onInputChange({
        checkbox: checkbox,
        numCheckbox: checkbox.length,
      });
      setCheckboxModified(false);
    }
  }, [checkboxModified, checkbox, onInputChange]);

  return (
    <div>
      {option?.optionItems?.map((checkbox, index) => (
        <div key={index} className="mb-2 mt-2 d-flex align-items-center ps-0 w-100">
          <label htmlFor={`checkbox${index + 1}`} className="checkboxes_label_circle me-3"></label>
          <input
            type="text"
            id={`checkbox${index + 1}`}
            name={`checkbox${index + 1}`}
            value={checkbox?.name}
            onChange={(e) => handleInputChange(checkbox, e.target.value)}
            className="options_input_field"
          />
          <img
            src={crossiconimg}
            alt="Cross"
            width="15px"
            className="ms-4 cross_icon_cursor"
            onClick={() => removeCheckbox(checkbox)}
          />
        </div>
      ))}
      <div className="mb-2 mt-2 d-flex align-items-center">
        <span className="option_label_text pt-2">
          <span className="text_blue_color" onClick={addCheckbox}>
            Add &quot;another&quot; Checkboxes
          </span>
        </span>
      </div>
      <div className="border-bottom-form-hr mt-3"></div>
    </div>
  );
};
Checkboxes.propTypes = {
  onInputChange: PropTypes.func.isRequired,
};

export default Checkboxes;
