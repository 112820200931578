import React, { useState, useEffect } from 'react';

const Timer = ({ targetDate, onExpires }) => {
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const targetTime = new Date(targetDate).getTime() - 5 * 60 * 60 * 1000;
    const timeDifference = targetTime - now;

    if (timeDifference <= 0) {
      if (onExpires) onExpires();
      // Timer expired
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: true,
      };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
      expired: false,
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const { days, hours, minutes, seconds, expired } = timeRemaining;

  if (expired) {
    return <div>Timer expired!</div>;
  }

  return (
    <div>
      <span>{days} </span>:&nbsp;
      <span>{hours} </span>:&nbsp;
      <span>{minutes} </span>:&nbsp;
      <span>{seconds}</span>
    </div>
  );
};

export default Timer;
