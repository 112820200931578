import React from 'react';
import './Airdropdata.css';
import Navbar from '../../components/Navbar/Navbar';
import Airdropdatatable from '../../components/Airdropdatatable/Airdropdatatable';
import SignIn from '../Signin/Signin';
import { useNavigate } from 'react-router-dom';
import useToken from './useToken';

const Airdropdata = () => {
  const { token, isValid, isLoading } = useToken();
  const navigate = useNavigate();

  if (isLoading) {
    return <p>Checking token validity...</p>;
  }

  if (!isValid) {
    // Token expired or invalid, redirect to login or handle error
    console.error('Token expired or invalid');
    localStorage.removeItem('token');
    navigate('/signin');
    // Assuming you have a Navigate component for navigation
  }
  return (
    <div>
      <>
        <Navbar />
        <Airdropdatatable />
      </>
    </div>
  );
};

export default Airdropdata;
