import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const useOptions = ({ options, setOptions, fields }) => {
  // Utility function to update or add an option item

  const updateOption = ({ fieldId, updatedItem }) => {
    // Check if the field with the provided fieldId exists
    if (!fieldId) return;

    const existingField = fields.find((field) => field.id === fieldId);

    if (!existingField) {
      // If the field does not exist, do nothing or handle as needed
      return;
    }

    const existingOptionsIndex = options.findIndex((option) => option?.fieldId === fieldId);

    // Check if options with the given fieldId already exist
    //   const existingOptionsIndex = options.findIndex((option) => option?.fieldId === fieldId);

    if (existingOptionsIndex === -1) {
      // Create a new option with a single default optionItem
      const option = {
        id: uuidv4(),
        fieldId: fieldId,
      };
      setOptions((prev) => [...prev, option]);
    }

    return existingField;
  };

  // Utility function to delete an option item by optionItemId
  const deleteOptionItem = ({ optionId, optionItemId }) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      const optionIndex = updatedOptions.findIndex((opt) => opt?.id === optionId);

      if (optionIndex !== -1) {
        // Filter out the option item to be deleted
        updatedOptions[optionIndex].optionItems = updatedOptions[optionIndex].optionItems.filter(
          (item) => item.id !== optionItemId,
        );
      }

      return updatedOptions;
    });
  };

  return { updateOption, deleteOptionItem };
};

export default useOptions;

// updateOptionItem
