import React, { useEffect, useState } from 'react';
import '../AirdropCards/Airdrophomecard.css';
import { useCustomFormikContext } from '../../hooks/contextFormikHook';

const ViewShortQuestion = ({ data, contentCount }) => {
  const { formik } = useCustomFormikContext();
  return (
    <div>
      <label htmlFor="shortQuestion" className='airdrops_home_card_text_p mb-2'><span className='fw-bold pe-1'>{data?.question}</span>{data?.isRequired && <span style={{ color: 'red' }}>*</span>}
</label>
      <input
        type="text"
        id="shortQuestion"
        name={data?.question + '-c-' + contentCount}
        onChange={formik?.handleChange}
        required={data?.isRequired}
        placeholder={data?.question}
        className="claimdrop_input mb-3"
      />
    </div>
  );
};

export default ViewShortQuestion;
